import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type { ApiError, ApiResponse } from 'entities/Response';
import { baseApi } from 'libs/axios';
import type { MailResponse } from 'services/mails/types';
import parseData from 'utils/parseData';

import { createUseTasMail, createUseTask } from './keys';
import type {
  MailData,
  TaskResponse,
  TasksParams,
  TasksResponse,
} from './types';

export const queryTasks = (params: TasksParams) => {
  return baseApi
    .get<ApiResponse<TasksResponse>>('to-dos', {
      params,
    })
    .then(parseData);
};

export const useTaskDetail = (
  id: number,
  options?: UseQueryOptions<TaskResponse, ApiError>,
) => {
  return useQuery({
    queryKey: createUseTask(id),
    queryFn: () =>
      baseApi.get<ApiResponse<TaskResponse>>(`to-dos/${id}`).then(parseData),
    ...options,
  });
};

export const useUpdateTask = (
  options?: UseMutationOptions<TaskResponse, ApiError, Partial<TaskResponse>>,
) => {
  return useMutation<TaskResponse, ApiError, Partial<TaskResponse>>({
    mutationFn: (data) =>
      baseApi
        .patch<ApiResponse<TaskResponse>>(`to-dos/${data.id}`, data)
        .then(parseData),
    ...options,
  });
};

export const useTaskMail = (
  id: number,
  options?: UseQueryOptions<MailResponse, ApiError>,
) => {
  return useQuery({
    queryKey: createUseTasMail(id),
    queryFn: () =>
      baseApi
        .get<ApiResponse<MailResponse>>(`to-dos/${id}/mail`)
        .then(parseData),
    ...options,
  });
};

export const useTaskCreateMail = (
  id: number,
  options?: UseMutationOptions<MailResponse, ApiError, MailData>,
) => {
  return useMutation<MailResponse, ApiError, MailData>({
    mutationFn: (data) =>
      baseApi
        .post<ApiResponse<MailResponse>>(`to-dos/${id}/mail`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(parseData),
    ...options,
  });
};
