import { Box, Tabs } from '@radix-ui/themes';
import { useParams } from 'react-router-dom';

import AccountForm from 'modules/companies/components/AccountForm';
import AddressForm from 'modules/companies/components/AddressForm';
import ManagementForm from 'modules/companies/components/ManagementForm';
import ReportsForm from 'modules/companies/components/ReportsForm';
import { useCompany } from 'services/companies';

import Container from 'components/Container';

const CompanieDetail = () => {
  const { id } = useParams();
  const { data, isLoading } = useCompany(id);

  return (
    <Container
      title={data?.name ? data?.name : 'Novo cliente'}
      loading={isLoading}
    >
      <Tabs.Root defaultValue="account">
        <Tabs.List>
          <Tabs.Trigger value="account">Dados da emrpesa</Tabs.Trigger>
          <Tabs.Trigger value="address" disabled={!data?.id}>
            Endereço
          </Tabs.Trigger>
          <Tabs.Trigger value="management" disabled={!data?.id}>
            Gestores
          </Tabs.Trigger>
          <Tabs.Trigger value="reports" disabled={!data?.id}>
            Tarefas
          </Tabs.Trigger>
        </Tabs.List>

        <Box style={{ maxWidth: 800 }} pt="6">
          <Tabs.Content value="account">
            <AccountForm />
          </Tabs.Content>
          <Tabs.Content value="address">
            <AddressForm />
          </Tabs.Content>
          <Tabs.Content value="management">
            <ManagementForm />
          </Tabs.Content>
          <Tabs.Content value="reports">
            <ReportsForm />
          </Tabs.Content>
        </Box>
      </Tabs.Root>
    </Container>
  );
};

export default CompanieDetail;
