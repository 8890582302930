import { useCallback, useEffect, useRef, useState } from 'react';

import { ArchiveIcon } from '@radix-ui/react-icons';
import * as Slider from '@radix-ui/react-slider';
import {
  AlertDialog,
  Box,
  Button,
  Flex,
  Spinner,
  Strong,
  Switch,
  Text,
  Tooltip,
} from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Toast } from 'primereact/toast';
import { Fragment } from 'react/jsx-runtime';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import type { Weekday } from 'rrule';
import { Frequency, RRule, rrulestr } from 'rrule';

import { ROUTES } from 'config/routes';
import useNavigate from 'hooks/useNavigate';
import {
  useCommunicationPurpose,
  useCreateCommunicationPurpose,
  useTemplatesMails,
  useUpdateCommunicationPurpose,
} from 'services/mails';
import {
  createUseCommunicationPurpose,
  createUseCommunicationPurposes,
} from 'services/mails/keys';

import Container from 'components/Container';
import InputField from 'components/InputField';
import InputLabel from 'components/InputLabel';
import SelectField from 'components/SelectField';

import type { FormData, FormPayload } from './types';
import { days, frequency } from './utils';

import './styles.css';
const ReportsFormScreen = () => {
  const [isRecurrence, setIsRecurrence] = useState<boolean>(false);

  const { navigate } = useNavigate();
  const { id } = useParams();
  const toastBC = useRef<Toast>(null);
  const queryClient = useQueryClient();

  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      is_private: false,
      range: [5, 10],
    },
  });

  const { data: purpose } = useCommunicationPurpose(id as string, {
    enabled: !!id,
  });

  const { mutate: createPurpose, isPending: isPendingCreatePurpose } =
    useCreateCommunicationPurpose();
  const { mutate: updatePurpose, isPending: isPendingUpdatePurpose } =
    useUpdateCommunicationPurpose();

  const isLoading = isPendingCreatePurpose || isPendingUpdatePurpose;

  const freq = watch('freq');
  const range = watch('range');

  const { data: templates } = useTemplatesMails({
    page_size: 300,
  });

  const formatRangeValue = useCallback((value: number = 0) => {
    if (value === 0) {
      return 'No dia do vencimento';
    }

    if (value < 0) {
      const number = Math.abs(value);
      return `${number} dia${number === 1 ? '' : 's'} antes`;
    }

    const number = Math.abs(value);
    return `${value} dia${number === 1 ? '' : 's'} depois`;
  }, []);

  const onSubmit = useCallback(
    (form: FormData) => {
      const payload: FormPayload = {
        name: form.name,
        is_private: form.is_private,
        acronym: form.acronym,
        is_archived: false,
        recurrence: form.recurrence ?? null,
        due_delta: Number(form.due_delta),
        template_id: Number(form.template_id),
      };

      if (isRecurrence) {
        const rule: Partial<{
          freq: Frequency;
          interval: number;
          dtstart: Date;
          byweekday: Weekday;
        }> = {
          freq: form.freq,
          interval: form.interval || 1,
          dtstart: new Date(form.dtstart),
        };

        if ((form.byday as unknown as number) >= 0) {
          rule.byweekday = form.byday as Weekday;
        }

        payload.recurrence = new RRule(rule).toString();

        payload.urgency_delta = range[0] * -1;
        payload.late_delta = range[1] * -1;
      }

      if (purpose?.id) {
        updatePurpose(
          {
            ...payload,
            id: purpose.id,
          },
          {
            onSuccess(data) {
              toastBC.current?.show({
                severity: 'success',
                summary: 'Tarefas',
                detail: 'Template atualizada com sucesso.',
                life: 5000,
              });

              queryClient.refetchQueries({
                queryKey: createUseCommunicationPurpose(data.id.toString()),
              });
            },
          },
        );
      } else {
        createPurpose(payload, {
          onSuccess(data) {
            toastBC.current?.show({
              severity: 'success',
              summary: 'Tarefas',
              detail: 'Tarefa criada com sucesso.',
              life: 5000,
            });

            navigate(`${ROUTES.PRIVATE.CONFIG_REPORTS}/${data.id}`);

            queryClient.refetchQueries({
              queryKey: createUseCommunicationPurposes(),
            });
          },
        });
      }
    },
    [
      createPurpose,
      isRecurrence,
      navigate,
      purpose,
      queryClient,
      range,
      updatePurpose,
    ],
  );

  const handleOnArchive = useCallback(() => {
    updatePurpose(
      {
        id: purpose?.id,
        is_archived: true,
      },
      {
        onSuccess() {
          toastBC.current?.show({
            severity: 'success',
            summary: 'Tarefas',
            detail: 'Template arquivada com sucesso.',
            life: 5000,
          });

          navigate(ROUTES.PRIVATE.CONFIG_REPORTS);

          queryClient.refetchQueries({
            queryKey: createUseCommunicationPurposes(),
          });
        },
      },
    );
  }, [navigate, purpose?.id, queryClient, updatePurpose]);

  useEffect(() => {
    if (purpose) {
      setValue('is_private', purpose.is_private);
      setValue('name', purpose.name);
      setValue('acronym', purpose.acronym);
      setValue('template_id', purpose.template_id);

      if (purpose.recurrence) {
        setIsRecurrence(true);
        const rule = rrulestr(purpose.recurrence);

        if (rule.options.byweekday) {
          setValue('byday', rule.options.byweekday[0] as unknown as Weekday);
        }

        setValue('freq', rule.options.freq);
        setValue('interval', rule.options.interval);
        setValue(
          'dtstart',
          format(new Date(rule.options.dtstart), 'yyyy-MM-dd'),
        );
        setValue('range', [
          purpose.urgency_delta * -1,
          purpose.late_delta * -1,
        ]);
        setValue('due_delta', purpose.due_delta);
      }
    }
  }, [purpose, setValue]);

  return (
    <Container title="Nova tarefa">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box maxWidth="800px">
          <Box pb="4">
            <Text as="label" size="2">
              <Flex gap="2">
                <Controller
                  control={control}
                  name="is_private"
                  render={({ field }) => (
                    <Fragment>
                      <Switch
                        size="1"
                        defaultChecked={field.value}
                        onCheckedChange={field.onChange}
                      />{' '}
                      Privado
                    </Fragment>
                  )}
                />
              </Flex>
            </Text>
          </Box>

          <InputField
            label="Nome"
            error={errors.name?.message}
            {...register('name', {
              required: 'Nome é obrigatório',
            })}
          />

          <InputField
            label="Sigla"
            error={errors.acronym?.message}
            {...register('acronym', {
              required: 'Sigla é obrigatório',
            })}
          />

          <Controller
            control={control}
            name="template_id"
            render={({ field }) => (
              <SelectField
                label="Template de e-mail"
                placeholder="Selecione um template"
                value={field.value}
                defaultValue={field.value}
                onValueChange={field.onChange}
                options={(templates?.results ?? []).map((i) => ({
                  value: i.id.toString(),
                  label: i.name,
                }))}
              />
            )}
          />

          <Box>
            <Box pb="4">
              <Text as="label" size="2">
                <Flex gap="2">
                  <Switch
                    size="1"
                    defaultChecked={isRecurrence}
                    checked={isRecurrence}
                    onCheckedChange={setIsRecurrence}
                  />{' '}
                  Esse tarefa será gerenciada?
                </Flex>
              </Text>
            </Box>

            {isRecurrence && (
              <Fragment>
                <Controller
                  control={control}
                  name="freq"
                  render={({ field }) => (
                    <SelectField
                      label="Frequência"
                      placeholder="Selecione uma frequência"
                      value={(field.value ?? '').toString()}
                      onValueChange={field.onChange}
                      options={frequency.map((i) => ({
                        value: i.id.toString(),
                        label: i.label,
                      }))}
                    />
                  )}
                />

                {Number(freq as Frequency) === Frequency.WEEKLY && (
                  <Controller
                    control={control}
                    name="byday"
                    render={({ field }) => (
                      <SelectField
                        label="Dia da semana"
                        placeholder="Selecione um dia da semana"
                        value={(field.value ?? '').toString()}
                        onValueChange={field.onChange}
                        options={days.map((i) => ({
                          value: i.id.toString(),
                          label: i.label,
                        }))}
                      />
                    )}
                  />
                )}

                <InputField
                  label="Intervalo"
                  error={errors.interval?.message}
                  type="number"
                  {...register('interval', {
                    required: 'Intervalo é obrigatório',
                  })}
                />

                <InputField
                  label="Começa em"
                  error={errors.dtstart?.message}
                  type="date"
                  {...register('dtstart', {
                    required: 'Data é obrigatório',
                  })}
                />

                <InputField
                  label="Vencimento após quantos dias (dias corridos)"
                  error={errors.due_delta?.message}
                  type="number"
                  {...register('due_delta', {
                    required: 'Dias é obrigatório',
                  })}
                />

                <Box mb="2">
                  <InputLabel text="Controle de status" />
                </Box>

                <Box>
                  <Box px="8" mb="2">
                    <Controller
                      control={control}
                      name="range"
                      render={({ field }) => (
                        <Slider.Root
                          defaultValue={field.value}
                          value={field.value}
                          onValueChange={field.onChange}
                          min={-15}
                          max={15}
                          className="SliderRoot"
                        >
                          <Slider.Track className="SliderTrack">
                            <Slider.Range className="SliderRange" />
                          </Slider.Track>

                          <Slider.Thumb style={{ outline: 'none' }}>
                            <Tooltip
                              content={formatRangeValue(range[0])}
                              defaultOpen
                            >
                              <Box className="SliderThumb" />
                            </Tooltip>
                          </Slider.Thumb>

                          <Slider.Thumb style={{ outline: 'none' }}>
                            <Tooltip
                              content={formatRangeValue(range[1])}
                              defaultOpen
                            >
                              <Box className="SliderThumb" />
                            </Tooltip>
                          </Slider.Thumb>
                        </Slider.Root>
                      )}
                    />
                  </Box>

                  <Flex align="center" justify="between">
                    <Text color="orange" weight="bold">
                      Pendente
                    </Text>
                    <Text weight="bold">Dia do vencimento</Text>
                    <Text color="tomato" weight="bold">
                      Atrasado
                    </Text>
                  </Flex>
                </Box>
              </Fragment>
            )}
          </Box>

          <Flex justify="between" mt="4">
            {purpose?.id ? (
              <AlertDialog.Root>
                <AlertDialog.Trigger>
                  <Button color="crimson" variant="soft" type="button">
                    <ArchiveIcon />
                    Arquivar
                  </Button>
                </AlertDialog.Trigger>
                <AlertDialog.Content maxWidth="450px">
                  <AlertDialog.Title>Atenção</AlertDialog.Title>
                  <AlertDialog.Description size="2">
                    Você está prestes a arquivar está tarefa. Tem certeza que
                    <br />
                    deseja arquivar o(a) <Strong>{purpose.name}</Strong>?
                  </AlertDialog.Description>

                  <Flex gap="3" mt="4" justify="end">
                    <AlertDialog.Cancel>
                      <Button variant="soft" color="gray">
                        Cancelar
                      </Button>
                    </AlertDialog.Cancel>
                    <AlertDialog.Action>
                      <Button
                        variant="solid"
                        color="red"
                        onClick={handleOnArchive}
                        loading={isPendingUpdatePurpose}
                      >
                        Arquivar
                      </Button>
                    </AlertDialog.Action>
                  </Flex>
                </AlertDialog.Content>
              </AlertDialog.Root>
            ) : (
              <Box />
            )}

            <Button size="3" type="submit" disabled={!isValid}>
              {isLoading && <Spinner />} Salvar
            </Button>
          </Flex>
        </Box>
      </form>

      <Toast ref={toastBC} />
    </Container>
  );
};

export default ReportsFormScreen;
