import { Box, Select } from '@radix-ui/themes';

import InputLabel from 'components/InputLabel';

import type { SelectFieldProps } from './types';

const SelectField = ({
  options,
  label,
  placeholder,
  ...props
}: SelectFieldProps) => {
  return (
    <Box mb="5" width="100%">
      {label && <InputLabel text={label} />}

      <Select.Root size="3" {...props}>
        <Select.Trigger placeholder={placeholder} style={{ width: '100%' }} />

        <Select.Content style={{ width: '100%' }}>
          {options?.map((i) => (
            <Select.Item key={i.value} value={i.value.toString()}>
              {i.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </Box>
  );
};

export default SelectField;
