import { useMemo, useState } from 'react';

import { Pencil1Icon } from '@radix-ui/react-icons';
import {
  Flex,
  Table,
  Badge,
  IconButton,
  Tooltip,
  Skeleton,
  Button,
} from '@radix-ui/themes';
import { Link } from 'react-router-dom';

import { PAGE_SIZE } from 'config/constants';
import { ROUTES } from 'config/routes';
import useNavigate from 'hooks/useNavigate';
import { useCompanies } from 'services/companies';

import Container from 'components/Container';
import InputSearch from 'components/InputSearch';
import Pagination from 'components/Pagination';

const CompaniesList = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const { navigate } = useNavigate();
  const { data, isPending } = useCompanies({
    page,
    page_size: PAGE_SIZE,
  });

  const companies = useMemo(() => {
    return data?.results.filter((i) =>
      `${i.name.toLowerCase()} ${i.trading_name.toLowerCase()}`.includes(
        search ?? '',
      ),
    );
  }, [data, search]);

  return (
    <Container>
      <Flex mb="4" align="center" justify="between">
        <InputSearch
          placeholder="Buscar cliente"
          onChange={(value) => setSearch(value)}
          total={data?.count ?? 0}
          isLoading={isPending}
          disabled={isPending}
        />

        <Skeleton loading={isPending}>
          <Button onClick={() => navigate(ROUTES.PRIVATE.COMPANIES_CREATE)}>
            Adicionar
          </Button>
        </Skeleton>
      </Flex>

      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Código</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Razão Social</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Agente</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Localização</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>CNPJ</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Categoria</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {isPending
            ? Array(10)
                .fill('')
                .map((_, i) => (
                  <Table.Row key={`loading_${i}`}>
                    <Table.Cell>
                      <Skeleton minWidth="100px" />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton minWidth="200px" />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton minWidth="200px" />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton minWidth="230px" />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton minWidth="180px" />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton />
                    </Table.Cell>
                  </Table.Row>
                ))
            : companies?.map((company) => (
                <Table.Row key={company.id} align="center">
                  <Table.RowHeaderCell>{company.code}</Table.RowHeaderCell>
                  <Table.Cell>{company.name}</Table.Cell>
                  <Table.Cell>{company.trading_name}</Table.Cell>
                  <Table.Cell>
                    {company.address !== null
                      ? `${company.address.city}, ${company.address.state}`
                      : '-'}
                  </Table.Cell>
                  <Table.Cell>{company.cnpj}</Table.Cell>
                  <Table.Cell>
                    <Badge
                      color={company.category === 'livre' ? 'orange' : 'indigo'}
                    >
                      <span style={{ textTransform: 'capitalize' }}>
                        {company.category}
                      </span>
                    </Badge>
                  </Table.Cell>
                  <Table.Cell>
                    <Badge
                      color={company.is_active === 'True' ? 'green' : 'red'}
                    >
                      {company.is_active === 'True' ? 'Ativo' : 'Inativo'}
                    </Badge>
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`${company.id}`}>
                      <Tooltip content="Editar">
                        <IconButton size="1" variant="soft">
                          <Pencil1Icon width={14} height={14} />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
        </Table.Body>
      </Table.Root>

      <Pagination
        num_pages={data?.num_pages ?? 0}
        onChange={(page) => setPage(page)}
      />
    </Container>
  );
};

export default CompaniesList;
