import { useState } from 'react';

import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import {
  Button,
  Checkbox,
  Dialog,
  Flex,
  ScrollArea,
  Spinner,
  Table,
} from '@radix-ui/themes';

import { useRecipients } from 'modules/mailing/stores/useRecipients';
import { useCompanies } from 'services/companies';
import type { Company } from 'services/companies/types';

import InputField from 'components/InputField';

import type { CompainesDialogProps } from './types';

const CompainesDialog = ({ trigger, isPending }: CompainesDialogProps) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState<string>();

  const { data } = useCompanies({
    page_size: 300,
  });

  const companies = useRecipients((state) => state.companies);
  const setCompanies = useRecipients((state) => state.setCompanies);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>

      <Dialog.Content style={{ maxWidth: 920 }}>
        <Dialog.Title>Selecionar clientes</Dialog.Title>
        <Dialog.Description aria-describedby="Clientes">
          <InputField
            placeholder="Buscar cliente"
            onChange={(e) => setSearch(e.target.value)}
            endAdornment={<MagnifyingGlassIcon />}
          />
        </Dialog.Description>

        <ScrollArea scrollbars="vertical" radius="full" style={{ height: 420 }}>
          <Table.Root variant="surface">
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell>
                  <Checkbox
                    size="2"
                    defaultChecked={data?.results.length === companies?.length}
                    onCheckedChange={(checked) => {
                      if (checked && data?.results) {
                        setCompanies(
                          Object.keys(data.results).flatMap(
                            (i) =>
                              (data?.results[i as never as number] as Company)
                                .id,
                          ),
                        );
                      } else {
                        setCompanies(undefined);
                      }
                    }}
                  />
                </Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>CNPJ</Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {data?.results
                .filter((i) =>
                  i.name.toLowerCase().includes((search ?? '')?.toLowerCase()),
                )
                .map((i) => (
                  <Table.Row key={i.id} align="center">
                    <Table.RowHeaderCell>
                      <Checkbox
                        size="2"
                        defaultChecked={companies?.includes(i.id)}
                        checked={companies?.includes(i.id)}
                        onCheckedChange={(checked) => {
                          if (checked) {
                            setCompanies([...(companies ?? []), i.id]);
                          } else {
                            setCompanies(
                              (companies ?? []).filter((f) => f !== i.id),
                            );
                          }
                        }}
                        value={i.id}
                      />
                    </Table.RowHeaderCell>
                    <Table.Cell>{i.name}</Table.Cell>
                    <Table.Cell>{i.cnpj}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table.Root>
        </ScrollArea>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button onClick={() => setOpen(false)} variant="soft" color="gray">
              Cancelar
            </Button>
          </Dialog.Close>

          <Dialog.Close>
            <Button disabled={isPending}>
              {isPending && <Spinner />}
              Selecionar
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default CompainesDialog;
