import { useMemo, useState } from 'react';

import {
  Badge,
  Box,
  Button,
  Dialog,
  Flex,
  Spinner,
  Text,
} from '@radix-ui/themes';

import ReportsDialog from 'modules/mailing/components//ReportsDialog';
import CompainesDialog from 'modules/mailing/components/CompaniesDialog';
import useFilterContacts from 'modules/mailing/hooks/useFilterContacts';
import { useRecipients } from 'modules/mailing/stores/useRecipients';
import { useCompanies } from 'services/companies';
import { useCommunicationPurposes } from 'services/mails';

import InputLabel from 'components/InputLabel';

import type { RecipientsProps } from './types';

const Recipients = ({ trigger, onChange }: RecipientsProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const companiesIds = useRecipients((state) => state.companies);
  const reportsIds = useRecipients((state) => state.reports);
  const setCompanies = useRecipients((state) => state.setCompanies);
  const setReports = useRecipients((state) => state.setReports);

  const { contacts, isPending } = useFilterContacts(
    companiesIds ?? [],
    reportsIds ?? [],
  );

  const { data: companies } = useCompanies({
    page_size: 300,
  });

  const { data: purposes } = useCommunicationPurposes({
    page_size: 300,
  });

  const purposesFiltered = useMemo(() => {
    return purposes?.pages
      ? purposes.pages
          .flatMap((page) => page.results)
          .filter((i) => !i.is_archived && i.recurrence.length > 0)
      : [];
  }, [purposes]);

  const companiesSelected = companies?.results.filter((i) =>
    companiesIds?.includes(i.id),
  );

  const reportsSelected = purposesFiltered?.filter((i) =>
    reportsIds?.includes(i.id),
  );

  return (
    <Dialog.Root onOpenChange={setOpen} open={open}>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>

      <Dialog.Content>
        <Dialog.Title>Filtrar destinatários</Dialog.Title>

        <Dialog.Description aria-describedby="Clientes">
          Filtrar por Clientes e Relatórios ou Clientes ou Relatórios.
        </Dialog.Description>

        <Box mb="4" mt="4">
          <InputLabel text="Clientes" />

          <CompainesDialog
            isPending={isPending}
            trigger={
              <Flex
                className="recipients"
                p="2"
                gap="2"
                wrap="wrap"
                style={{
                  borderColor: 'var(--gray-7)',
                }}
              >
                {companiesSelected?.length ? (
                  companiesSelected?.map((i, index) => (
                    <Badge key={index}>{i.name}</Badge>
                  ))
                ) : (
                  <Text color="gray">Selecionar cliente</Text>
                )}
              </Flex>
            }
          />
        </Box>

        <Box mb="4">
          <InputLabel text="Tarefas" />

          <ReportsDialog
            isPending={isPending}
            trigger={
              <Flex
                className="recipients"
                p="2"
                gap="2"
                wrap="wrap"
                style={{
                  borderColor: 'var(--gray-7)',
                }}
              >
                {reportsSelected?.length ? (
                  reportsSelected?.map((i, index) => (
                    <Badge key={index}>{i.name}</Badge>
                  ))
                ) : (
                  <Text color="gray">Selecionar tarefas</Text>
                )}
              </Flex>
            }
          />
        </Box>

        <Flex gap="4" justify="between" pt="4">
          <Box>
            <Button
              color="red"
              variant="soft"
              onClick={() => {
                setCompanies(undefined);
                setReports(undefined);
              }}
            >
              Limpar filtro
            </Button>
          </Box>
          <Flex gap="4" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancelar
              </Button>
            </Dialog.Close>

            <Dialog.Close>
              <Button onClick={() => onChange(contacts)} disabled={isPending}>
                {isPending && <Spinner />}
                Filtrar
              </Button>
            </Dialog.Close>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default Recipients;
