import { create } from 'zustand';

import type { RecipientsProps } from './types';

export const useRecipients = create<RecipientsProps>((set) => {
  const setCompanies = (companies: number[]) => {
    set((state) => ({
      ...state,
      companies,
    }));
  };

  const setReports = (reports: number[]) => {
    set((state) => ({
      ...state,
      reports,
    }));
  };

  return {
    contacts: undefined,
    setCompanies,
    setReports,
  };
});
