import { useCallback } from 'react';

import { Table } from '@radix-ui/themes';

import type { MailRecipientsType } from 'services/mails/types';
import { useTaskMail } from 'services/tasks';

import Container from 'components/Container';

import type { RecipientsProps } from './types';

const Recipients = ({ taskId }: RecipientsProps) => {
  const { data: mail, isLoading } = useTaskMail(taskId);

  const parsedGroup = useCallback((type: MailRecipientsType) => {
    const group: { [key in MailRecipientsType]: string } = {
      contact: 'Contato',
      manager: 'Gestão',
      support: 'Suporte',
    };

    return group[type];
  }, []);

  return (
    <Container title="Destinatários" loading={isLoading}>
      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Email</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Grupo</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {mail?.to_do_recipients.map((contact) => (
            <Table.Row key={contact.email}>
              <Table.RowHeaderCell>{contact.name}</Table.RowHeaderCell>
              <Table.Cell>{contact.email}</Table.Cell>
              <Table.Cell>{parsedGroup(contact.type)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </Container>
  );
};

export default Recipients;
