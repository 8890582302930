import type { QueryKey } from '@tanstack/react-query';

import type { CommunicationPurposesParams, TemplateMailsParams } from './types';

export const createUseCommunicationPurposes = (
  params?: CommunicationPurposesParams,
): QueryKey => [`useCommunicationPurposes`, params];

export const createUseCommunicationPurpose = (id: string): QueryKey => [
  `useCommunicationPurpose`,
  id,
];

export const createUseTemplateMails = (
  params?: TemplateMailsParams,
): QueryKey => [`useTemplateMails`, params];

export const createUseMails = (params?: TemplateMailsParams): QueryKey => [
  `useMails`,
  params,
];

export const createUseMail = (id: string): QueryKey => [`useMail`, id];

export const createUseTemplateMail = (id: string): QueryKey => [
  `useTemplateMail`,
  id,
];

export const createUseMailingGroup = (): QueryKey => ['useMailingGroup'];
