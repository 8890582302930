import { useCallback, useEffect, useRef } from 'react';

import { AlertDialog, Button, Flex, Spinner, Strong } from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';
import { Toast } from 'primereact/toast';
import { useForm } from 'react-hook-form';

import { PAGE_SIZE } from 'config/constants';
import { ROUTES } from 'config/routes';
import useNavigate from 'hooks/useNavigate';
import {
  useCreateEmployee,
  useDeleteEmployee,
  useUpdateEmployee,
} from 'services/staffs';
import { createUseEmployee, createUseStaffs } from 'services/staffs/keys';

import InputField from 'components/InputField';

import type { EmployeeFormsProps, FormData } from './types';

const EmployeeForm = ({ data }: EmployeeFormsProps) => {
  const { navigate } = useNavigate();

  const { mutate: createEmployee, isPending: isPendingCreateEmployee } =
    useCreateEmployee();
  const { mutate: updateEmployee, isPending: isPendingUpdateEmployee } =
    useUpdateEmployee();
  const { mutate: deleteEmployee, isPending: isPendingDeleteEmployee } =
    useDeleteEmployee();

  const isLoading = isPendingUpdateEmployee || isPendingCreateEmployee;

  const queryClient = useQueryClient();

  const toastBC = useRef<Toast>(null);

  const {
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    if (data?.id) {
      setValue('name', data.name);
      setValue('email', data.email);
    }
  }, [data, setValue]);

  const onSubmit = useCallback(
    (formData: FormData) => {
      if (data?.id) {
        updateEmployee(
          {
            ...formData,
            id: data.id,
            cpf: data.cpf,
            rg: data.rg,
          },
          {
            onSuccess() {
              toastBC.current?.show({
                severity: 'success',
                summary: 'Equipe',
                detail: 'Mebro da equipe atualizado com sucesso.',
                life: 5000,
              });

              queryClient.invalidateQueries({
                queryKey: createUseEmployee(data.id.toString()),
              });
            },
            onError() {
              toastBC.current?.show({
                severity: 'error',
                summary: 'Equipe',
                detail: 'Erro ao atualizar membro da equipe.',
                life: 5000,
              });
            },
          },
        );
      } else {
        createEmployee(
          {
            ...formData,
            cpf: '',
            rg: '',
          },
          {
            onSuccess(data) {
              toastBC.current?.show({
                severity: 'success',
                summary: 'Equipe',
                detail: 'Mebro da equipe adicionado com sucesso.',
                life: 5000,
              });

              navigate(`${ROUTES.PRIVATE.STAFFS}/${data.id}`);
              queryClient.refetchQueries({
                queryKey: createUseStaffs({
                  page: 1,
                  page_size: PAGE_SIZE,
                  order_by: 'name',
                }),
              });
            },
            onError() {
              toastBC.current?.show({
                severity: 'error',
                summary: 'Equipe',
                detail: 'Erro ao cadastrar membro da equipe.',
                life: 5000,
              });
            },
          },
        );
      }
    },
    [createEmployee, data, navigate, queryClient, updateEmployee],
  );

  const onDeleteEmployee = useCallback(() => {
    if (data?.id) {
      deleteEmployee(
        {
          id: data.id,
        },
        {
          onSuccess() {
            toastBC.current?.show({
              severity: 'success',
              summary: 'Equipe',
              detail: 'Mebro da equipe removido com sucesso.',
              life: 5000,
            });

            navigate(`${ROUTES.PRIVATE.STAFFS}`);
            queryClient.refetchQueries({
              queryKey: createUseStaffs({
                page: 1,
                page_size: PAGE_SIZE,
                order_by: 'name',
              }),
            });
          },
          onError() {
            toastBC.current?.show({
              severity: 'error',
              summary: 'Equipe',
              detail: 'Erro ao remover membro da equipe.',
              life: 5000,
            });
          },
        },
      );
    }
  }, [data?.id, deleteEmployee, navigate, queryClient]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField
        label="Nome"
        error={errors.name?.message}
        {...register('name', {
          required: 'Nome é obrigatório',
        })}
      />

      <InputField
        label="E-mail"
        error={errors.email?.message}
        {...register('email', {
          required: 'E-mail é obrigatório',
        })}
      />

      <Flex gap="4">
        <Flex
          style={{
            flex: 1,
          }}
        >
          {data?.id && data?.name && (
            <AlertDialog.Root>
              <AlertDialog.Trigger>
                <Button color="crimson" variant="soft" type="button">
                  Excluir
                </Button>
              </AlertDialog.Trigger>
              <AlertDialog.Content maxWidth="450px">
                <AlertDialog.Title>Atenção</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Você está prestes a excluir um membro da equipe. Tem certeza
                  de deseja excluir o(a) <Strong>{data.name}</Strong>?
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">
                      Cancelar
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button
                      variant="solid"
                      color="red"
                      onClick={onDeleteEmployee}
                      loading={isPendingDeleteEmployee}
                    >
                      Excluir
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
          )}
        </Flex>

        <Flex justify="end">
          <Button size="3" type="submit" disabled={isLoading}>
            {isLoading && <Spinner />} Salvar
          </Button>
        </Flex>
      </Flex>

      <Toast ref={toastBC} />
    </form>
  );
};

export default EmployeeForm;
