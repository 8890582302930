function processRow<T>(row: T[]) {
  let finalVal = '';

  for (let j = 0; j < row.length; j++) {
    let innerValue = row[j] === null ? '' : row[j];

    if (row[j] instanceof Date) {
      innerValue = row[j];
    }

    let result = (innerValue as string).replace(/"/g, '""');

    if (result.search(/("|,|\n)/g) >= 0) {
      result = '"' + result + '"';
    }

    if (j > 0) {
      finalVal += ',';
    }

    finalVal += result;
  }

  return finalVal + '\n';
}

export function exportToCsv<T>(filename: string, rows: T[]) {
  let csvFile = '';

  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i] as T[]);
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');

    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
