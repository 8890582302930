import { GearIcon } from '@radix-ui/react-icons';
import { Avatar, DropdownMenu, Flex, Text } from '@radix-ui/themes';

import { ROUTES } from 'config/routes';
import useAuth from 'hooks/useAuth';
import useNavigate from 'hooks/useNavigate';
import { useCurrentUser } from 'services/user';

const Header = () => {
  const { data } = useCurrentUser();
  const { logout } = useAuth();
  const { navigate } = useNavigate();

  return (
    <Flex align="center" justify="end" p="4" style={{ minHeight: 80 }}>
      <Flex gap="4" align="center">
        {data && (
          <Avatar fallback={String(data.me.name.charAt(0))} radius="full" />
        )}

        <Text>Olá, {data?.me.name}</Text>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <GearIcon width="24" height="24" />
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item disabled>Perfil</DropdownMenu.Item>

            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>Configurações</DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent>
                <DropdownMenu.Item
                  onClick={() => navigate(ROUTES.PRIVATE.CONFIG_REPORTS)}
                >
                  Tarefas
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  onClick={() => navigate(ROUTES.PRIVATE.TEMPLATES_MAILS)}
                >
                  Mailing
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  onClick={() => navigate(ROUTES.PRIVATE.STAFFS)}
                >
                  Equipe
                </DropdownMenu.Item>
              </DropdownMenu.SubContent>
            </DropdownMenu.Sub>

            <DropdownMenu.Separator />
            <DropdownMenu.Item color="red" onClick={logout}>
              Sair
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Flex>
    </Flex>
  );
};

export default Header;
