import { FileIcon, OpenInNewWindowIcon } from '@radix-ui/react-icons';
import { Box, Card, Flex, Text, Tooltip } from '@radix-ui/themes';

import type { Props } from './types';

const AttachmentsMail = ({ filename, id, url }: Props) => {
  return (
    <Box mb="4">
      <Card mt="4">
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'none',
          }}
        >
          <Flex gap="3" align="center" justify="between">
            <Flex gap="3" align="center">
              <FileIcon width="24" height="24" color="gray" />

              <Box>
                <Text as="div" size="2" weight="bold" color="gray">
                  {filename}
                </Text>
                <Text as="div" size="2" color="gray">
                  {id}
                </Text>
              </Box>
            </Flex>

            <Tooltip content="Ver anexo">
              <OpenInNewWindowIcon
                width="20"
                height="20"
                cursor="pointer"
                color="gray"
              />
            </Tooltip>
          </Flex>
        </a>
      </Card>
    </Box>
  );
};

export default AttachmentsMail;
