import { useMemo, useState } from 'react';

import { Pencil1Icon } from '@radix-ui/react-icons';
import {
  Badge,
  Flex,
  IconButton,
  Skeleton,
  Table,
  Tooltip,
} from '@radix-ui/themes';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';

import { PAGE_SIZE } from 'config/constants';
import { ROUTES } from 'config/routes';
import useNavigate from 'hooks/useNavigate';
import { useMails } from 'services/mails';

import Container from 'components/Container';
import InputSearch from 'components/InputSearch';
import Pagination from 'components/Pagination';

const HistoricScreen = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const { data: mails, isPending } = useMails({
    page_size: PAGE_SIZE,
    page,
  });

  const { navigate } = useNavigate();

  const filtered = useMemo(() => {
    const s = (search ?? '')?.toLowerCase();

    return mails?.results.filter(
      (i) =>
        i?.sent_by?.email.includes(s) ||
        i?.variables?.email_subject.includes(s) ||
        i?.variables?.email_title.includes(s),
    );
  }, [mails?.results, search]);

  return (
    <Container>
      <Flex mb="4" align="center" justify="between">
        <InputSearch
          placeholder="Buscar mail"
          onChange={(value) => setSearch(value)}
          total={mails?.count ?? 0}
          disabled={isPending}
          isLoading={isPending}
        />
      </Flex>

      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Assunto</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Título</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Data de envio</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Enviado por</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Tipo de e-mail</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {isPending
            ? Array(10)
                .fill('')
                .map((_, i) => (
                  <Table.Row key={`loading_${i}`}>
                    <Table.Cell>
                      <Skeleton minWidth="200px" />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton minWidth="250px" />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton minWidth="150px" />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton minWidth="160px" />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton minWidth="180px" />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton />
                    </Table.Cell>
                  </Table.Row>
                ))
            : filtered?.map((i) => (
                <Table.Row key={i.id} align="center">
                  <Table.RowHeaderCell>
                    {i.variables.email_subject}
                  </Table.RowHeaderCell>
                  <Table.Cell>{i.variables.email_title}</Table.Cell>
                  <Table.Cell>
                    {i.sent_at
                      ? format(new Date(i.sent_at), 'PPPp', { locale: ptBR })
                      : '-'}
                  </Table.Cell>
                  <Table.Cell>{i.sent_by?.email}</Table.Cell>
                  <Table.Cell>
                    {i.to_do_id ? (
                      <Badge>Tarefas</Badge>
                    ) : (
                      <Badge color="green">Mailing</Badge>
                    )}
                  </Table.Cell>

                  <Table.Cell>
                    <Tooltip content="Editar">
                      <IconButton size="1" variant="soft">
                        <Pencil1Icon
                          width={14}
                          height={14}
                          onClick={() =>
                            navigate(
                              `${ROUTES.PRIVATE.MAILING_HISTORIC}/${i.id}`,
                            )
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </Table.Cell>
                </Table.Row>
              ))}
        </Table.Body>
      </Table.Root>

      <Pagination
        num_pages={mails?.num_pages ?? 0}
        onChange={(page) => setPage(page)}
      />
    </Container>
  );
};

export default HistoricScreen;
