import { useMemo, useState } from 'react';

import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import {
  Button,
  Checkbox,
  Dialog,
  Flex,
  ScrollArea,
  Spinner,
  Table,
} from '@radix-ui/themes';

import { useRecipients } from 'modules/mailing/stores/useRecipients';
import { useCommunicationPurposes } from 'services/mails';
import type { CommunicationPurposeResponse } from 'services/mails/types';

import InputField from 'components/InputField';

import type { ReportsDialogProps } from './types';

const ReportsDialog = ({ trigger, isPending }: ReportsDialogProps) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState<string>();

  const reports = useRecipients((state) => state.reports);
  const setReports = useRecipients((state) => state.setReports);

  const { data: purposes } = useCommunicationPurposes({
    page_size: 400,
  });

  const purposesFiltered = useMemo(() => {
    return purposes?.pages
      ? purposes.pages
          .flatMap((page) => page.results)
          .filter((i) => !i.is_archived && i.recurrence.length > 0)
      : [];
  }, [purposes]);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>

      <Dialog.Content style={{ maxWidth: 800 }}>
        <Dialog.Title>Selecionar tarefas</Dialog.Title>
        <Dialog.Description aria-describedby="Clientes">
          <InputField
            placeholder="Buscar tarefas"
            onChange={(e) => setSearch(e.target.value)}
            endAdornment={<MagnifyingGlassIcon />}
          />
        </Dialog.Description>

        <ScrollArea scrollbars="vertical" radius="full" style={{ height: 420 }}>
          <Table.Root variant="surface">
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell>
                  <Checkbox
                    size="2"
                    defaultChecked={purposesFiltered.length === reports?.length}
                    onCheckedChange={(checked) => {
                      if (checked && purposesFiltered.length) {
                        setReports(
                          Object.keys(purposesFiltered).flatMap(
                            (i) =>
                              (
                                purposesFiltered[
                                  i as never as number
                                ] as CommunicationPurposeResponse
                              ).id,
                          ),
                        );
                      } else {
                        setReports(undefined);
                      }
                    }}
                  />
                </Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Sigla</Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {purposesFiltered
                .filter((i) =>
                  i.name.toLowerCase().includes((search ?? '')?.toLowerCase()),
                )
                .map((i, index) => (
                  <Table.Row key={index} align="center">
                    <Table.RowHeaderCell>
                      <Checkbox
                        size="2"
                        defaultChecked={reports?.includes(i.id)}
                        checked={reports?.includes(i.id)}
                        onCheckedChange={(checked) => {
                          if (checked) {
                            setReports([...(reports ?? []), i.id]);
                          } else {
                            setReports(
                              (reports ?? []).filter((f) => f !== i.id),
                            );
                          }
                        }}
                        value={i.id}
                      />
                    </Table.RowHeaderCell>
                    <Table.Cell>{i.name}</Table.Cell>
                    <Table.Cell>{i.acronym}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table.Root>
        </ScrollArea>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Cancelar
            </Button>
          </Dialog.Close>

          <Dialog.Close>
            <Button disabled={isPending}>
              {isPending && <Spinner />}
              Selecionar
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ReportsDialog;
