export const ROUTES = {
  PUBLIC: {
    ROOT: '/',
    FORGOT_PASSWORD: 'forgot-password',
    CONFIRM_PASSWORD: 'create-password',
  },
  PRIVATE: {
    ROOT: 'p',
    DASHBOARD: 'dashboard',
    TASKS: 'tasks',
    TASK_DETAIL: 'tasks/:id',
    COMPANIES: 'companies',
    COMPANIES_CREATE: 'companies-create',
    COMPANIE: 'companies/:id',
    CONTACTS: 'contacts',
    CONTACT_DETAIL: 'contacts/:id',
    STAFFS: 'staffs',
    STAFFS_CREATE: 'staffs-create',
    EMPLOYEE_DETAIL: 'staffs/:id',

    // Mailing
    MAILING: 'mailing',
    MAILING_HISTORIC: 'mailing-historic',
    MAILING_HISTORIC_FORM: 'mailing-historic/:id',

    // Settings
    TEMPLATES_MAILS: 'templates',
    TEMPLATES_MAILS_FORM: 'templates/:id',
    TEMPLATES_MAILS_CREATE: 'templates-create',
    CONFIG_REPORTS: 'config-reports',
    CONFIG_REPORTS_CREATE: 'config-reports-create',
    CONFIG_REPORTS_DETAIL: 'config-reports/:id',
  },
};
