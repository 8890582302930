import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { InfoCircledIcon } from '@radix-ui/react-icons';
import {
  Box,
  Button,
  Callout,
  Card,
  CheckboxGroup,
  Dialog,
  Flex,
  ScrollArea,
  Switch,
} from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';
import { Toast } from 'primereact/toast';
import { Controller, useForm } from 'react-hook-form';

import { useCompanyContact, useCompanyContactUpdate } from 'services/companies';
import {
  createUseCompanyContact,
  createUseCompanyContacts,
} from 'services/companies/keys';
import type { ContactResponse } from 'services/companies/types';
import { useContactCreate, useContacts } from 'services/contacts';
import { useCommunicationPurposes } from 'services/mails';

import InputField from 'components/InputField';
import InputLabel from 'components/InputLabel';

import type { ContactDialogProps, FormData } from './types';

const ContactDialog = ({
  companyId,
  contactId,
  trigger,
}: ContactDialogProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const {
    register,
    watch,
    setValue,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      is_active: true,
    },
  });

  const email = watch('email');
  const isActive = watch('is_active');

  const toastBC = useRef<Toast>(null);

  const queryClient = useQueryClient();

  const {
    data: contacts,
    refetch,
    isLoading,
    isError,
  } = useContacts(
    {
      email,
    },
    {
      enabled: false,
    },
  );

  const { mutate: updateContact, isPending: isPendingUpdate } =
    useCompanyContactUpdate(companyId);
  const { mutate: createContact, isPending: isPendingCreate } =
    useContactCreate();

  const { data: contact } = useCompanyContact(companyId, contactId, {
    enabled: Boolean(open && companyId && contactId),
  });

  const { data: purposes } = useCommunicationPurposes({
    page: 1,
    page_size: 100,
  });

  const contactFiltered = useMemo(() => {
    return contacts?.results.filter((i) => i.email === email)[0] || contact;
  }, [contact, contacts, email]);

  const purposesFiltered = useMemo(() => {
    return purposes?.pages
      ? purposes.pages
          .flatMap((page) => page.results)
          .filter((i) => !i.is_archived && i.recurrence.length > 0)
      : [];
  }, [purposes]);

  const contactLinkedToCompany = useMemo(() => {
    const parsed = contactFiltered?.companies?.map((c) => c.name) ?? [];
    const result = parsed.filter((c, index) => {
      return parsed.indexOf(c) === index;
    });

    return `Este contato está vinculado ao cliente: ${result ? result.join(', ') : ''}.`;
  }, [contactFiltered]);

  const onSubmit = useCallback(
    (form: FormData) => {
      if (contactFiltered?.id) {
        updateContact(
          {
            ...form,
            id: contactFiltered.id,
            communications: form.communications?.map((i) => ({
              communication_purpose_id: i,
              company_id: companyId,
            })),
          },
          {
            onSuccess() {
              toastBC.current?.show({
                severity: 'success',
                summary: 'Contato',
                detail: 'Contato atualizado com sucesso.',
                life: 5000,
              });

              queryClient.refetchQueries({
                queryKey: createUseCompanyContacts(companyId),
              });

              queryClient.refetchQueries({
                queryKey: createUseCompanyContact(
                  companyId,
                  contactFiltered.id,
                ),
              });

              setOpen(false);
            },
          },
        );
      } else {
        createContact(
          {
            ...form,
            communications: form.communications?.map((i) => ({
              communication_purpose_id: i,
              company_id: companyId,
            })),
          },
          {
            onSuccess() {
              toastBC.current?.show({
                severity: 'success',
                summary: 'Contato',
                detail: 'Contato adicionado com sucesso.',
                life: 5000,
              });

              queryClient.refetchQueries({
                queryKey: createUseCompanyContacts(companyId),
              });

              setOpen(false);
            },
          },
        );
      }
    },
    [companyId, contactFiltered?.id, createContact, queryClient, updateContact],
  );

  const populateFormData = useCallback(
    (contact: ContactResponse) => {
      setValue('email', contact.email);
      setValue('name', contact.name);
      setValue('is_active', contact.is_active);
      setValue('phone', contact.phone);

      setValue(
        'communications',
        Object.values(
          contact.communications
            .filter((i) => i.company_id === companyId)
            .map((i) => i.communication_purpose_id),
        ),
      );
    },
    [companyId, setValue],
  );

  useEffect(() => {
    if (contactFiltered && open) {
      populateFormData(contactFiltered);
    }
  }, [contactFiltered, open, populateFormData]);

  useEffect(() => {
    if (contactFiltered) {
      populateFormData(contactFiltered);
    } else if (isError) {
      reset({
        communications: [],
        is_active: true,
        name: '',
        phone: '',
      });
    }
  }, [contactFiltered, populateFormData, isError, reset]);

  useEffect(() => {
    if (open === false) {
      reset();
    }
  }, [open, reset]);

  return (
    <Dialog.Root onOpenChange={setOpen} open={open}>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>

      <Dialog.Content maxWidth="600px">
        <Dialog.Title>Contato</Dialog.Title>

        {(contacts?.results.length ?? 0) > 0 && (
          <Callout.Root color="yellow" mb="4">
            <Callout.Icon>
              <InfoCircledIcon />
            </Callout.Icon>
            <Callout.Text>{contactLinkedToCompany}</Callout.Text>
          </Callout.Root>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Flex gap="2" mb="4">
              <Switch
                defaultChecked={isActive}
                checked={isActive}
                onCheckedChange={(checked) => setValue('is_active', checked)}
              />{' '}
              Ativo
            </Flex>
            <InputField
              label="E-mail"
              error={errors.email?.message}
              {...register('email', {
                required: 'E-mail é obrigatório',
              })}
              disabled={Boolean(contactId)}
              endAdornment={
                !contactId ? (
                  <Button
                    type="button"
                    loading={isLoading}
                    onClick={() => refetch()}
                  >
                    Buscar
                  </Button>
                ) : undefined
              }
            />

            <InputField
              label="Nome"
              disabled={isLoading}
              error={errors.name?.message}
              {...register('name', { required: 'Nome é obrigatório' })}
            />

            <InputField
              label="Telefone"
              disabled={isLoading}
              mask="(dd) ddddd-dddd"
              {...register('phone')}
            />

            <Box>
              <Box mb="2">
                <InputLabel text="Tarefas" />
              </Box>
              <Card>
                <ScrollArea style={{ height: 240 }}>
                  <Controller
                    control={control}
                    name="communications"
                    render={({ field: { onChange, value } }) => (
                      <CheckboxGroup.Root
                        value={value}
                        defaultValue={value}
                        name="example"
                        onValueChange={onChange}
                      >
                        {purposesFiltered.map((i) => (
                          <CheckboxGroup.Item
                            value={i.id.toString()}
                            key={i.id}
                          >
                            {i.name}
                          </CheckboxGroup.Item>
                        ))}
                      </CheckboxGroup.Root>
                    )}
                  />
                </ScrollArea>
              </Card>
            </Box>
          </Box>

          <Flex gap="4" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancelar
              </Button>
            </Dialog.Close>

            <Button type="submit" loading={isPendingUpdate || isPendingCreate}>
              Salvar
            </Button>
          </Flex>
        </form>

        <Toast ref={toastBC} />
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ContactDialog;
