import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { PaperPlaneIcon, PlusIcon } from '@radix-ui/react-icons';
import { Badge, Box, Button, Dialog, Flex, Spinner } from '@radix-ui/themes';
import { Editor } from 'primereact/editor';
import { Toast } from 'primereact/toast';
import { Controller, useForm } from 'react-hook-form';

import { useCreateEmail, useSendMail, useTemplatesMails } from 'services/mails';
import { useCurrentUser } from 'services/user';

import InputError from 'components/InputError';
import InputField from 'components/InputField';
import InputFile from 'components/InputFile';
import InputLabel from 'components/InputLabel';
import RecipientsDialog from 'components/RecipientsDialog';
import SelectField from 'components/SelectField';

import type { FormData, FormMailingProps } from './types';

// eslint-disable-next-line complexity
const FormMailing = ({ contacts, trigger, open }: FormMailingProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormData>();

  const toastBC = useRef<Toast>(null);

  const recipients = watch('recipients') ?? [];
  const templateId = watch('template_id');

  const { data: user } = useCurrentUser();
  const { data: templates } = useTemplatesMails();
  const { mutate: createEmail, isPending: isPendingCreateEmail } =
    useCreateEmail();
  const { mutateAsync: sendMail, isPending: isPendingSendMail } = useSendMail();

  const isLoading = isPendingCreateEmail || isPendingSendMail;

  const template = useMemo(() => {
    return templates?.results.find((i) => i.id.toString() === templateId);
  }, [templates?.results, templateId]);

  const createFormData = useCallback(
    (form: FormData) => {
      const formData = new FormData();
      const file = form.attachments?.[0];

      formData.append('attachments', (file as File) ?? null);
      formData.append('template_id', form.template_id);
      formData.append('recipients', JSON.stringify(form.recipients));
      formData.append('variables', JSON.stringify(form.variables));
      formData.append('sent_by_id', JSON.stringify(user?.me.id));

      return formData;
    },
    [user?.me.id],
  );

  const onSubmit = useCallback(
    (form: FormData) => {
      const formData = createFormData(form);

      createEmail(formData, {
        onSuccess(data) {
          sendMail(
            {
              id: data.id as number,
              user_id: user?.me.id as number,
            },
            {
              onSuccess() {
                toastBC.current?.show({
                  severity: 'success',
                  summary: 'E-mail',
                  detail: 'E-mail enviado com sucesso.',
                  life: 5000,
                });

                reset();
                setValue('attachments', undefined);
                setOpenDialog(false);
              },
              onError() {
                toastBC.current?.show({
                  severity: 'error',
                  summary: 'E-mail',
                  detail: 'Erro ao enviar e-mail, tente novamente.',
                  life: 5000,
                });
              },
            },
          );
        },
      });
    },
    [createEmail, createFormData, reset, sendMail, setValue, user?.me.id],
  );

  const handleSaveEmail = useCallback(
    (form: FormData) => {
      if (isValid) {
        const formData = createFormData(form);

        createEmail(formData, {
          onSuccess() {
            toastBC.current?.show({
              severity: 'success',
              summary: 'E-mail',
              detail: 'E-mail salvo com sucesso.',
              life: 5000,
            });
            setOpenDialog(false);
          },
          onError() {
            toastBC.current?.show({
              severity: 'error',
              summary: 'E-mail',
              detail: 'Erro ao salvar e-mail, tente novamente.',
              life: 5000,
            });
          },
        });
      }
    },
    [createEmail, createFormData, isValid],
  );

  useEffect(() => {
    if (contacts) {
      setValue(
        'recipients',
        (contacts ?? []).map((i) => ({
          email: i.email,
          name: i.name,
          type: 'contact',
        })),
      );
    }
  }, [contacts, setValue]);

  useEffect(() => {
    if (open) {
      setOpenDialog(open);
    }
  }, [open]);

  return (
    <Fragment>
      <Dialog.Root
        open={openDialog}
        defaultOpen={openDialog}
        onOpenChange={setOpenDialog}
      >
        {trigger && <Dialog.Trigger>{trigger}</Dialog.Trigger>}

        <Dialog.Content style={{ maxWidth: 800 }}>
          <Dialog.Title>Novo e-mail</Dialog.Title>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="template_id"
              render={({ field }) => (
                <SelectField
                  label="Template Mailing"
                  placeholder="Selecione um template"
                  value={field.value}
                  onValueChange={field.onChange}
                  options={(templates?.results ?? []).map((i) => ({
                    value: i.id.toString(),
                    label: i.name,
                  }))}
                />
              )}
            />

            {(contacts ?? []).length > 0 && (
              <>
                <InputLabel text="Destinatários" />
                <RecipientsDialog
                  defaultValue={(contacts ?? []).map((i) => ({
                    email: i.email,
                    name: i.name,
                    type: 'contact',
                  }))}
                  value={recipients}
                  onChange={(recipients) => setValue('recipients', recipients)}
                  trigger={
                    <Box pb="5">
                      <Flex
                        className="recipients"
                        p="2"
                        gap="2"
                        style={{
                          borderColor:
                            recipients.length === 0
                              ? 'var(--red-11)'
                              : 'var(--gray-7)',
                        }}
                      >
                        {recipients.slice(0, 2).map((contact) => (
                          <Box key={contact.email}>
                            <Badge radius="full">{contact.email}</Badge>
                          </Box>
                        ))}

                        <Box>
                          {recipients.length > 2 && (
                            <Badge radius="full">
                              + {recipients.length - 2}
                            </Badge>
                          )}
                        </Box>
                      </Flex>

                      {recipients.length === 0 && (
                        <InputError error="Nenhum contato selecionado" />
                      )}
                    </Box>
                  }
                />
              </>
            )}

            {template?.required_variables.includes('email_subject') && (
              <InputField
                label="Assunto"
                placeholder="Digite aqui o assunto"
                error={errors.variables?.email_subject?.message}
                {...register('variables.email_subject', {
                  required: 'Assunto é obrigatório',
                })}
              />
            )}

            {template?.required_variables.includes('email_title') && (
              <InputField
                label="Título do e-mail"
                placeholder="Digite aqui o título"
                error={errors.variables?.email_title?.message}
                {...register('variables.email_title', {
                  required: 'Título é obrigatório',
                })}
              />
            )}

            <Box mb="4">
              <Controller
                name="variables.email_body"
                control={control}
                render={({ field }) => (
                  <Editor
                    id={field.name}
                    name={field.name}
                    value={field.value}
                    onTextChange={(e) => field.onChange(e.htmlValue)}
                    style={{ height: '320px' }}
                  />
                )}
              />

              {errors.variables?.email_body?.message && (
                <InputError error={errors.variables.email_body.message} />
              )}
            </Box>

            <InputFile
              error={errors.attachments?.message}
              {...register('attachments')}
            />

            <Flex gap="4" align="center" justify="between">
              <Box>
                <Button
                  onClick={handleSubmit(handleSaveEmail)}
                  disabled={!isValid}
                >
                  {isLoading ? <Spinner /> : <PlusIcon />}
                  Salvar
                </Button>
              </Box>

              <Flex gap="4" align="center" justify="between">
                <Dialog.Close>
                  <Button variant="soft" color="gray">
                    Cancelar
                  </Button>
                </Dialog.Close>

                <Button
                  color="green"
                  type="submit"
                  disabled={!templateId || recipients.length === 0}
                >
                  {isLoading ? <Spinner /> : <PaperPlaneIcon />}
                  Enviar
                </Button>
              </Flex>
            </Flex>
          </form>
        </Dialog.Content>
      </Dialog.Root>

      <Toast ref={toastBC} />
    </Fragment>
  );
};

export default FormMailing;
