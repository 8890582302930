import { Fragment, memo, useCallback, useMemo, useState } from 'react';

import {
  DownloadIcon,
  EnvelopeClosedIcon,
  EnvelopeOpenIcon,
  PlusCircledIcon,
  StarIcon,
} from '@radix-ui/react-icons';
import {
  Badge,
  Box,
  Button,
  Flex,
  Table,
  Text,
  Tooltip,
} from '@radix-ui/themes';

import { ROUTES } from 'config/routes';
import useNavigate from 'hooks/useNavigate';
import FavoritesDialog from 'modules/mailing/components/FavoritesDialog';
import FormMailing from 'modules/mailing/components/FormMailing';
import Recipients from 'modules/mailing/components/Recipients';
import type {
  CommunicationsData,
  ContactResponse,
} from 'services/contacts/types';
import { useCommunicationPurposes } from 'services/mails';
import type { MailRecipients } from 'services/mails/types';
import { exportToCsv } from 'utils/documents';

import Container from 'components/Container';

const MailingScreen = () => {
  const [contacts, setContacts] = useState<ContactResponse[]>([]);
  const [contactsFavorites, setContactsFavorites] =
    useState<MailRecipients[]>();

  const { navigate } = useNavigate();

  const { data: purposes } = useCommunicationPurposes({
    page_size: 100,
  });

  const purposesFiltered = useMemo(() => {
    return purposes?.pages
      ? purposes.pages
          .flatMap((page) => page.results)
          .filter((i) => !i.is_archived && i.recurrence.length > 0)
      : [];
  }, [purposes]);

  const parseContactComunication = useCallback(
    (data: CommunicationsData[]) => {
      const reports: number[] = [];

      data.forEach((c) => {
        if (!reports.includes(Number(c.communication_purpose_id))) {
          reports.push(Number(c.communication_purpose_id));
        }
      });

      return reports.map((i) => {
        const ft = purposesFiltered.find((c) => i === c.id);

        return {
          name: ft?.name,
          acronym: ft?.acronym,
        };
      });
    },
    [purposesFiltered],
  );

  const handleOnExportToCSV = useCallback(() => {
    const rows = [['emmpresas', 'nome', 'e-mail']];

    contacts.forEach((contact) => {
      contact.companies.forEach((company) => {
        rows.push([company.trading_name, contact.name, contact.email]);
      });
    });

    exportToCsv(`mailing-${new Date().getTime()}.csv`, rows);
  }, [contacts]);

  return (
    <Container>
      <Flex direction="row" align="center" justify="between">
        <Flex>
          {contacts.length === 0 ? (
            <Text>Selecione destinatários</Text>
          ) : (
            <Text>Total: {contacts?.length}</Text>
          )}
        </Flex>

        <Flex gap="4">
          {contacts.length !== 0 && (
            <Fragment>
              <FormMailing
                contacts={contacts?.map((i) => ({
                  email: i.email,
                  name: i.name,
                  type: 'contact',
                }))}
                trigger={
                  <Button color="green">
                    <EnvelopeClosedIcon />
                    Novo e-mail
                  </Button>
                }
              />

              <Button
                variant="surface"
                color="green"
                onClick={handleOnExportToCSV}
              >
                <DownloadIcon /> Exportar CSV
              </Button>
            </Fragment>
          )}
          <Recipients
            onChange={setContacts}
            trigger={
              <Button>
                <PlusCircledIcon />
                Criar filtro
              </Button>
            }
          />

          <Button
            variant="soft"
            color="gray"
            onClick={() => navigate(ROUTES.PRIVATE.MAILING_HISTORIC)}
          >
            <EnvelopeOpenIcon />
            Enviados
          </Button>

          <FavoritesDialog
            onChange={(contacts) => {
              setContactsFavorites(undefined);

              setTimeout(() => {
                setContactsFavorites(contacts);
              }, 200);
            }}
            trigger={
              <Button variant="soft">
                <StarIcon />
                Lista salvas
              </Button>
            }
          />
        </Flex>
      </Flex>

      <Box pt="4">
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>E-mail</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Ref. Client</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Tarefas</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {contacts.map((i) => (
              <Table.Row key={i.id} align="center">
                <Table.Cell>{i.name}</Table.Cell>
                <Table.Cell>{i.email}</Table.Cell>
                <Table.Cell>
                  {i.companies.map((c, index) => (
                    <Text>
                      {c.name}
                      {index + 1 !== i.companies.length ? ', ' : ''}
                    </Text>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  <Flex gap="2" wrap="wrap">
                    {parseContactComunication(i.communications).map(
                      (t, index) => (
                        <Fragment key={index}>
                          {t.acronym ? (
                            <Tooltip content={t.name}>
                              <Badge>{t.acronym}</Badge>
                            </Tooltip>
                          ) : null}
                        </Fragment>
                      ),
                    )}
                  </Flex>
                </Table.Cell>
              </Table.Row>
            ))}

            {contacts.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan={4}>Nenhum valor encontrado</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table.Root>
      </Box>

      {(contactsFavorites ?? [])?.length !== 0 && (
        <FormMailing contacts={contactsFavorites} open={true} />
      )}
    </Container>
  );
};

export default memo(MailingScreen);
