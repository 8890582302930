import { useEffect } from 'react';

import { Box, Flex, ScrollArea } from '@radix-ui/themes';
import Lottie from 'lottie-react';
import { Outlet } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import Header from 'layouts/Default/Header';
import Menu from 'layouts/Default/Menu';
import { useCurrentUser } from 'services/user';

import AnimationLoading from './animation-loading-2.json';

const Default = () => {
  const { token, logout } = useAuth();

  const { isLoading } = useCurrentUser();

  useEffect(() => {
    if (!token) {
      logout();
    }
  }, [token, logout]);

  return (
    <>
      {isLoading ? (
        <Flex width="100vw" height="100vh" align="center" justify="center">
          <Box width="780px">
            <Lottie animationData={AnimationLoading} />
          </Box>
        </Flex>
      ) : (
        <Flex overflow="hidden" height="100vh">
          <Flex
            justify="center"
            style={{
              width: 120,
              height: '100vh',
              // backgroundColor: 'var(--accent-a3)',
              borderRightWidth: 1,
              borderRightStyle: 'solid',
              borderRightColor: 'var(--accent-a3)',
            }}
          >
            <Menu />
          </Flex>

          <Flex
            direction="column"
            align="stretch"
            style={{
              flex: 1,
            }}
          >
            <Box style={{ height: 80 }}>
              <Header />
            </Box>

            <Box
              style={{ flex: 1, backgroundColor: 'var(--accent-a2)' }}
              overflow="hidden"
            >
              <ScrollArea scrollbars="vertical">
                <Box p="4" style={{ flex: 1 }}>
                  <Outlet />
                </Box>
              </ScrollArea>
            </Box>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default Default;
