import { useEffect } from 'react';

import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Box, Callout, Flex, Switch, Text } from '@radix-ui/themes';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import type { Company } from 'services/companies/types';
import { useContact } from 'services/contacts';

import Container from 'components/Container';
import InputField from 'components/InputField';

import type { FormData } from './types';

const ContactDetail = () => {
  const { id } = useParams();
  const { data: contact } = useContact(id as string, {
    enabled: !!id,
  });

  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    if (contact) {
      setValue('name', contact.name);
      setValue('email', contact.email);
      setValue('is_active', contact.is_active);
      setValue('phone', contact.phone);
    }
  }, [contact, setValue]);

  return (
    <Container title="Contatos">
      <Box maxWidth="800px">
        {contact && (contact?.companies ?? []).length !== 0 && (
          <Callout.Root color="yellow" mb="4">
            <Callout.Icon>
              <InfoCircledIcon />
            </Callout.Icon>
            <Callout.Text>
              Este contato está vinculado aos seguintes clientes:{' '}
              {Object.keys(contact.companies)
                .flatMap(
                  (i) =>
                    (contact.companies[i as never as number] as Company).name,
                )
                .join(', ')}
            </Callout.Text>
          </Callout.Root>
        )}

        <Controller
          control={control}
          name="is_active"
          render={({ field }) => (
            <Text as="label" size="2">
              <Flex gap="2" mb="4">
                <Switch
                  defaultChecked={field.value}
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />{' '}
                Ativo
              </Flex>
            </Text>
          )}
        />

        <InputField
          label="Nome"
          placeholder="Digite o nome aqui"
          error={errors.name?.message}
          {...register('name', {
            required: 'Nome é obrigatório',
          })}
        />

        <InputField
          label="E-mail"
          placeholder="Digite o e-mail aqui"
          error={errors.name?.message}
          {...register('email', {
            required: 'E-mail é obrigatório',
          })}
        />

        <InputField
          label="Telefone"
          placeholder="Digite o telefone aqui"
          mask={['(dd) dddd-dddd', '(dd) ddddd-dddd']}
          {...register('phone')}
        />
      </Box>
    </Container>
  );
};

export default ContactDetail;
