import { Fragment, useCallback, useRef, useState } from 'react';

import {
  EyeOpenIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
} from '@radix-ui/react-icons';
import {
  AlertDialog,
  Button,
  Dialog,
  Flex,
  IconButton,
  ScrollArea,
  Strong,
  Table,
  Text,
  Tooltip,
} from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';
import { Toast } from 'primereact/toast';

import { useDeleteMailingGroup, useMailingGroup } from 'services/mails';
import { createUseMailingGroup } from 'services/mails/keys';

import InputField from 'components/InputField';
import RecipientsDialog from 'components/RecipientsDialog';

import type { FavoritesDialogProps } from './types';

const FavoritesDialog = ({ trigger, onChange }: FavoritesDialogProps) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState<string>();

  const toastBC = useRef<Toast>(null);

  const { data: mails } = useMailingGroup();
  const { mutate: deleteMailing, isPending: isPendingDeleteMailing } =
    useDeleteMailingGroup();

  const queryClient = useQueryClient();

  const handleOnDeleteMailing = useCallback(
    (id: number) => {
      deleteMailing(
        {
          id,
        },
        {
          onSuccess() {
            queryClient.invalidateQueries({
              queryKey: createUseMailingGroup(),
            });

            toastBC.current?.show({
              severity: 'success',
              summary: 'Favoritos',
              detail: 'Lista de mailing excluída com sucesso.',
              life: 5000,
            });
          },
          onError() {
            toastBC.current?.show({
              severity: 'error',
              summary: 'Favoritos',
              detail: 'Erro ao excluir lista de mailing. Tente novamente.',
              life: 5000,
            });
          },
        },
      );
    },
    [deleteMailing, queryClient],
  );

  return (
    <Fragment>
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Trigger>{trigger}</Dialog.Trigger>

        <Dialog.Content style={{ maxWidth: 860 }}>
          <Dialog.Title>Selecionar lista</Dialog.Title>
          <Dialog.Description aria-describedby="Lista de contatos">
            <InputField
              placeholder="Buscar lista"
              onChange={(e) => setSearch(e.target.value)}
              endAdornment={<MagnifyingGlassIcon />}
            />
          </Dialog.Description>

          <ScrollArea
            scrollbars="vertical"
            radius="full"
            style={{ height: 420 }}
          >
            <Table.Root variant="surface">
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell width="200px">
                    Mome
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>E-mails</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {mails?.results
                  .filter((i) =>
                    i.name
                      .toLowerCase()
                      .includes((search ?? '')?.toLowerCase()),
                  )
                  .map((i, index) => (
                    <Table.Row key={index} align="center">
                      <Table.Cell>{i.name}</Table.Cell>
                      <Table.Cell>
                        {i.recipients.slice(0, 5).map((e, index) => (
                          <Text key={index}>
                            {e.email}
                            {index === 4 ? '' : ', '}
                          </Text>
                        ))}{' '}
                        ... [+
                        {i.recipients.slice(5, i.recipients.length).length}]
                      </Table.Cell>
                      <Table.Cell>
                        <Flex gap="4">
                          <Tooltip content="Ver e-mails">
                            <RecipientsDialog
                              defaultValue={i.recipients}
                              value={i.recipients}
                              disabled
                              trigger={
                                <IconButton size="1" variant="soft">
                                  <EyeOpenIcon width={14} height={14} />
                                </IconButton>
                              }
                            />
                          </Tooltip>

                          <Tooltip content="Selecionar">
                            <IconButton
                              size="1"
                              onClick={() => {
                                onChange(i.recipients);
                                setOpen(false);
                              }}
                            >
                              <PlusIcon width={14} height={14} />
                            </IconButton>
                          </Tooltip>

                          <AlertDialog.Root>
                            <Tooltip content="Excluir">
                              <AlertDialog.Trigger>
                                <IconButton
                                  size="1"
                                  variant="soft"
                                  color="red"
                                  type="button"
                                >
                                  <TrashIcon width={14} height={14} />
                                </IconButton>
                              </AlertDialog.Trigger>
                            </Tooltip>

                            <AlertDialog.Content maxWidth="450px">
                              <AlertDialog.Title>Atenção</AlertDialog.Title>
                              <AlertDialog.Description size="2">
                                Você está prestes a excluir uma lista de mailing
                                salva. Tem certeza de deseja excluir o(a){' '}
                                <Strong>{i.name}</Strong>?
                              </AlertDialog.Description>

                              <Flex gap="3" mt="4" justify="end">
                                <AlertDialog.Cancel>
                                  <Button variant="soft" color="gray">
                                    Cancelar
                                  </Button>
                                </AlertDialog.Cancel>
                                <AlertDialog.Action>
                                  <Button
                                    variant="solid"
                                    color="red"
                                    onClick={() => handleOnDeleteMailing(i.id)}
                                    loading={isPendingDeleteMailing}
                                  >
                                    Excluir
                                  </Button>
                                </AlertDialog.Action>
                              </Flex>
                            </AlertDialog.Content>
                          </AlertDialog.Root>
                        </Flex>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table.Root>
          </ScrollArea>
        </Dialog.Content>
      </Dialog.Root>

      <Toast ref={toastBC} />
    </Fragment>
  );
};

export default FavoritesDialog;
