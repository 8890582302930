import { RRule } from 'rrule';

export const frequency = [
  {
    id: RRule.DAILY,
    label: 'Diária',
  },
  {
    id: RRule.WEEKLY,
    label: 'Semanal',
  },
  {
    id: RRule.MONTHLY,
    label: 'Mensal',
  },
  {
    id: RRule.YEARLY,
    label: 'Anual',
  },
];

export const days = [
  {
    id: RRule.SU.weekday,
    label: 'Domingo',
  },
  {
    id: RRule.MO.weekday,
    label: 'Segunda-feira',
  },
  {
    id: RRule.TU.weekday,
    label: 'Terça-feira',
  },
  {
    id: RRule.WE.weekday,
    label: 'Quarta-feira',
  },
  {
    id: RRule.TH.weekday,
    label: 'Quinta-feira',
  },
  {
    id: RRule.FR.weekday,
    label: 'Sexta-feira',
  },
  {
    id: RRule.SA.weekday,
    label: 'Sábado',
  },
];
