import { useCallback, useState } from 'react';

import { EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons';
import { Box, Button, Card, Flex, Heading, IconButton } from '@radix-ui/themes';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ROUTES } from 'config/routes';
import useAuth from 'hooks/useAuth';
import { useSignIn } from 'services/auth';

import IconLoading from 'components/IconLoading';
import InputField from 'components/InputField';

import type { FormSignInData } from './types';

import './style.css';

const SignIn = () => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const { login } = useAuth();

  const { mutate, isPending } = useSignIn({
    onSuccess({ access_token }) {
      login(access_token);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormSignInData>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onChangePasswordVisible = useCallback(() => {
    setPasswordVisible((value) => !value);
  }, []);

  const onSubmit = useCallback(
    (data: FormSignInData) => {
      mutate({
        username: data.email,
        password: data.password,
      });
    },
    [mutate],
  );

  return (
    <Flex align="center" justify="center" height="100%">
      <Card size="3" variant="surface" className="signin-form">
        <Heading as="h1" color="gray" mb="6">
          Bem-vindo
        </Heading>

        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            label="E-mail"
            placeholder="Digite seu e-mail aqui"
            type="email"
            error={errors.email?.message}
            {...register('email', {
              required: 'E-mail é obrigatório',
            })}
          />

          <InputField
            label="Senha"
            placeholder="Digite sua senha aqui"
            type={passwordVisible ? 'text' : 'password'}
            error={errors.password?.message}
            endAdornment={
              <IconButton
                size="1"
                variant="ghost"
                type="button"
                onClick={onChangePasswordVisible}
              >
                {passwordVisible ? (
                  <EyeOpenIcon height="14" width="14" />
                ) : (
                  <EyeNoneIcon height="14" width="14" />
                )}
              </IconButton>
            }
            {...register('password', {
              required: 'Senha é obrigatório',
            })}
          />

          <Flex align="center" justify="between">
            <Box>
              <Button size="3" type="submit" disabled={isPending}>
                {isPending && <IconLoading />} Enviar
              </Button>
            </Box>

            <Box>
              <Link to={ROUTES.PUBLIC.FORGOT_PASSWORD}>
                Esqueci minha senha
              </Link>
            </Box>
          </Flex>
        </form>
      </Card>
    </Flex>
  );
};

export default SignIn;
