/* eslint-disable complexity */
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { InfoCircledIcon, PaperPlaneIcon } from '@radix-ui/react-icons';
import { Badge, Box, Button, Callout, Flex, Spinner } from '@radix-ui/themes';
import { formatDate } from 'date-fns';
import { Editor } from 'primereact/editor';
import { Toast } from 'primereact/toast';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useUpdateAttachments } from 'services/attachments';
import {
  useCreateEmail,
  useMail,
  useSendMail,
  useTemplatesMails,
  useUpdateEmail,
} from 'services/mails';
import { useCurrentUser, useSystemUser } from 'services/user';

import AttachmentsMail from 'components/AttachmentsMail';
import Container from 'components/Container';
import InputError from 'components/InputError';
import InputField from 'components/InputField';
import InputFile from 'components/InputFile';
import InputLabel from 'components/InputLabel';
import RecipientsDialog from 'components/RecipientsDialog';
import SelectField from 'components/SelectField';

import type { FormData } from './types';

const HistoricFormScreen = () => {
  const { id } = useParams();
  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>();

  const toastBC = useRef<Toast>(null);

  const recipients = watch('recipients') ?? [];
  const templateId = watch('template_id');

  const { data: user } = useCurrentUser();
  const { data: templates } = useTemplatesMails();
  const { mutate: createEmail, isPending: isPendingCreateEmail } =
    useCreateEmail();
  const { mutateAsync: sendMail, isPending: isPendingSendMail } = useSendMail();

  const { data: mail } = useMail(id as string, {
    enabled: !!id,
  });

  const { data: systemUser, refetch: refetchSystemUser } = useSystemUser(
    mail?.sent_by_id as string,
    {
      enabled: Boolean(mail?.sent_by_id),
    },
  );

  const isDisabled = Boolean(mail?.sent_at);

  const { mutate: updateEmail } = useUpdateEmail();

  const { mutate: updateAttachments, isPending: isPendingUpdateAttachments } =
    useUpdateAttachments();

  console.log(
    createEmail,
    sendMail,
    updateAttachments,
    user,
    isPendingUpdateAttachments,
  );

  const isLoading = isPendingCreateEmail || isPendingSendMail;

  const template = useMemo(() => {
    return templates?.results.find((i) => i.id.toString() === templateId);
  }, [templates?.results, templateId]);

  const onSubmit = useCallback(
    (form: FormData) => {
      updateEmail(
        {
          id: mail?.id as number,
          variables: form.variables,
          recipients: form.recipients,
        },
        {
          onSuccess() {
            toastBC.current?.show({
              severity: 'success',
              summary: 'E-mail',
              detail: 'E-mail atualizado com sucesso.',
              life: 5000,
            });
            // sendMail(
            //   {
            //     id: data.id as number,
            //     user_id: user?.me.id as number,
            //   },
            //   {
            //     onSuccess() {
            //       toastBC.current?.show({
            //         severity: 'success',
            //         summary: 'E-mail',
            //         detail: 'E-mail atualizado com sucesso.',
            //         life: 5000,
            //       });

            //       reset();
            //       setValue('attachments', undefined);
            //     },
            //     onError() {
            //       toastBC.current?.show({
            //         severity: 'error',
            //         summary: 'E-mail',
            //         detail: 'Erro ao enviar e-mail, tente novamente.',
            //         life: 5000,
            //       });
            //     },
            //   },
            // );
          },
        },
      );
    },
    [mail?.id, updateEmail],
  );

  useEffect(() => {
    if (mail) {
      setValue('recipients', mail.recipients);
      setValue('template_id', mail.template_id);
      setValue('variables', mail.variables);
      // setValue('variables', mail.variables);

      refetchSystemUser();
    }
  }, [mail, setValue, refetchSystemUser]);

  return (
    <Container>
      {mail?.sent_at && (
        <Callout.Root mb="4" color="green">
          <Callout.Icon>
            <InfoCircledIcon />
          </Callout.Icon>
          <Callout.Text>
            Este e-mail foi enviado no dia{' '}
            {formatDate(new Date(mail.sent_at), "dd/MM/yyyy 'às' HH:mm")} por{' '}
            {systemUser?.name}.
          </Callout.Text>
        </Callout.Root>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="template_id"
          render={({ field }) => (
            <SelectField
              label="Template Mailing"
              placeholder="Selecione um template"
              value={field.value}
              defaultValue={field.value}
              onValueChange={field.onChange}
              disabled={isDisabled}
              options={(templates?.results ?? []).map((i) => ({
                value: i.id.toString(),
                label: i.name,
              }))}
            />
          )}
        />

        {(mail?.recipients ?? []).length > 0 && (
          <>
            <InputLabel text="Destinatários" />
            <RecipientsDialog
              defaultValue={mail?.recipients ?? []}
              value={recipients}
              onChange={(recipients) => setValue('recipients', recipients)}
              disabled={isDisabled}
              trigger={
                <Box pb="5">
                  <Flex
                    className="recipients"
                    p="2"
                    gap="2"
                    style={{
                      borderColor:
                        recipients.length === 0
                          ? 'var(--red-11)'
                          : 'var(--gray-7)',
                    }}
                  >
                    {recipients.slice(0, 2).map((contact) => (
                      <Box key={contact.email}>
                        <Badge radius="full">{contact.email}</Badge>
                      </Box>
                    ))}

                    <Box>
                      {recipients.length > 2 && (
                        <Badge radius="full">+ {recipients.length - 2}</Badge>
                      )}
                    </Box>
                  </Flex>

                  {recipients.length === 0 && (
                    <InputError error="Nenhum contato selecionado" />
                  )}
                </Box>
              }
            />
          </>
        )}

        {template?.required_variables.includes('email_subject') && (
          <InputField
            label="Assunto"
            placeholder="Digite aqui o assunto"
            disabled={isDisabled}
            error={errors.variables?.email_subject?.message}
            {...register('variables.email_subject', {
              required: 'Assunto é obrigatório',
            })}
          />
        )}

        {template?.required_variables.includes('email_title') && (
          <InputField
            label="Título do e-mail"
            placeholder="Digite aqui o título"
            disabled={isDisabled}
            error={errors.variables?.email_title?.message}
            {...register('variables.email_title', {
              required: 'Título é obrigatório',
            })}
          />
        )}

        <Box mb="4">
          <Controller
            name="variables.email_body"
            control={control}
            render={({ field }) => (
              <Editor
                id={field.name}
                name={field.name}
                value={field.value}
                disabled={isDisabled}
                onTextChange={(e) => field.onChange(e.htmlValue)}
                style={{ height: '320px' }}
              />
            )}
          />

          {errors.variables?.email_body?.message && (
            <InputError error={errors.variables.email_body.message} />
          )}
        </Box>

        <InputFile
          error={errors.attachments?.message}
          {...register('attachments')}
        />

        {mail?.attachments && mail.attachments.length > 0 && (
          <AttachmentsMail
            filename={mail.attachments[0].filename}
            id={mail.attachments[0].id}
            url={mail.attachments[0].url}
          />
        )}

        <Flex gap="4" align="center" justify="between">
          {/* <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancelar
              </Button>
            </Dialog.Close> */}

          <Button
            color="green"
            disabled={!templateId || recipients.length === 0}
          >
            {isLoading ? <Spinner /> : <PaperPlaneIcon />}
            Enviar
          </Button>

          <Button
            type="submit"
            disabled={
              !isValid ||
              Boolean(mail?.sent_at && mail.attachments?.length === 0)
            }
          >
            {isLoading && <Spinner />}
            Salvar
          </Button>
        </Flex>
      </form>

      <Toast ref={toastBC} />
    </Container>
  );
};

export default HistoricFormScreen;
