import { Fragment } from 'react';

import { Box, Flex, Heading, Separator } from '@radix-ui/themes';

import IconLoading from 'components/IconLoading';

import type { ContainerProps } from './types';

const Container = ({ children, loading, title }: ContainerProps) => {
  return (
    <Box
      p="4"
      style={{
        background: 'var(--accent-1)',
        borderRadius: 'var(--radius-2)',
      }}
    >
      {loading ? (
        <Flex align="center" justify="center" style={{ height: 300 }}>
          <IconLoading width="48" height="48" />
        </Flex>
      ) : (
        <Fragment>
          {title && (
            <Box pb="4">
              {typeof title === 'string' ? (
                <Flex py="4" gap="4">
                  <Heading size="4" color="gray">
                    {title}
                  </Heading>
                </Flex>
              ) : (
                title
              )}

              <Separator my="3" size="4" />
            </Box>
          )}

          {children}
        </Fragment>
      )}
    </Box>
  );
};

export default Container;
