import { useMemo } from 'react';

import { Badge, Box } from '@radix-ui/themes';
import type { BadgeProps } from '@radix-ui/themes/dist/cjs/components/badge.js';

import { TaskStatus } from 'services/tasks/types';

import type { StatusColorProps } from './types';

import './styles.css';

const StatusColor = ({ status, text, isCreated }: StatusColorProps) => {
  const colors = useMemo<{ [key in TaskStatus]: BadgeProps['color'] }>(() => {
    return {
      [TaskStatus.Arquivado]: 'gray',
      [TaskStatus.Atrasado]: 'red',
      [TaskStatus.Concluído]: 'green',
      [TaskStatus.Ignorado]: 'indigo',
      [TaskStatus.Pendente]: 'amber',
      [TaskStatus.Urgente]: 'orange',
    };
  }, []);

  return (
    <Box position="relative" style={{ lineHeight: 0 }}>
      <Badge color={colors[status]} variant="solid">
        {text}
      </Badge>

      {isCreated && <Box className="created-email" />}
    </Box>
  );
};

export default StatusColor;
