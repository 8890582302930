import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';

import type { ApiError, ApiResponse } from 'entities/Response';
import { baseApi } from 'libs/axios';
import parseData from 'utils/parseData';

import {
  createUseCompanies,
  createUseCompany,
  createUseCompanyContact,
  createUseCompanyContacts,
} from './keys';
import type {
  CompaniesResponse,
  CompaniesParams,
  CompanyResponse,
  CompanyData,
  CompanyContactResponse,
  ContactResponse,
  ContactData,
} from './types';

export const useCompanies = (
  params: CompaniesParams,
  options?: UseQueryOptions<CompaniesResponse, ApiError, CompaniesResponse>,
) => {
  return useQuery({
    queryKey: createUseCompanies(params),
    queryFn: ({ pageParam = params?.page ?? 1 }) =>
      baseApi
        .get(`companies`, {
          params: {
            page: pageParam,
            ...params,
          },
        })
        .then((r) => r.data),
    ...options,
  });
};

export const useCompany = (
  id?: string,
  options?: Partial<
    UseQueryOptions<CompanyResponse, ApiError, CompanyResponse>
  >,
) => {
  return useQuery({
    enabled: !!id,
    queryKey: createUseCompany(id as string),
    queryFn: () =>
      baseApi
        .get<ApiResponse<CompanyResponse>>(`companies/${id}`)
        .then(parseData),
    ...options,
  });
};

export const useCreateCompany = (
  options?: UseMutationOptions<CompanyResponse, ApiError, CompanyData>,
) => {
  return useMutation<CompanyResponse, ApiError, CompanyData>({
    mutationFn: (data) =>
      baseApi
        .post<ApiResponse<CompanyResponse>>(`companies`, data)
        .then(parseData),
    ...options,
  });
};

export const useUpdateCompany = (
  options?: UseMutationOptions<CompanyResponse, ApiError, CompanyData>,
) => {
  return useMutation<CompanyResponse, ApiError, CompanyData>({
    mutationFn: (data) =>
      baseApi
        .patch<ApiResponse<CompanyResponse>>(`companies/${data.id}`, data)
        .then(parseData),
    ...options,
  });
};

export const useDeleteCompany = (
  options?: UseMutationOptions<CompanyResponse, ApiError, { id: number }>,
) => {
  return useMutation<CompanyResponse, ApiError, { id: number }>({
    mutationFn: ({ id }) =>
      baseApi
        .delete<ApiResponse<CompanyResponse>>(`companies/${id}`)
        .then(parseData),
    ...options,
  });
};

export const useCompanyContacts = (
  companyId?: string,
  options?: UseQueryOptions<
    CompanyContactResponse,
    ApiError,
    CompanyContactResponse
  >,
) => {
  return useQuery({
    queryKey: createUseCompanyContacts(companyId as string),
    enabled: !!companyId,
    queryFn: () =>
      baseApi.get(`companies/${companyId}/contacts`).then(parseData),
    ...options,
  });
};

export const useCompanyContact = (
  companyId: string,
  contactId?: number,
  options?: Partial<
    UseQueryOptions<ContactResponse, ApiError, ContactResponse>
  >,
) => {
  return useQuery({
    queryKey: createUseCompanyContact(companyId, contactId ?? 0),
    enabled: !!(companyId && contactId),
    queryFn: () =>
      baseApi
        .get(`companies/${companyId}/contacts/${contactId}`)
        .then(parseData),
    ...options,
  });
};

export const useCompanyContactUpdate = (
  companyId: string,
  options?: Partial<UseMutationOptions<ContactResponse, ApiError, ContactData>>,
) => {
  return useMutation({
    mutationFn: (data) =>
      baseApi.put(`companies/${companyId}/contacts/${data.id}`, data),
    ...options,
  });
};

export const useCompanyContactDelete = (
  companyId: string,
  options?: Partial<
    UseMutationOptions<ContactResponse, ApiError, Partial<ContactData>>
  >,
) => {
  return useMutation({
    mutationFn: (data) =>
      baseApi.put(`contacts/${data.id}/companies/${companyId}`, {
        communications: [],
      }),
    ...options,
  });
};

// updateContactByCompany (client, { payload, config }) {
//   return client.put(`contacts/${payload.contact_id}/companies/${payload.company_id}`, payload.body, config)
// },

// updateContact (client, { payload, config }) {
//   return client.put(`companies/${payload.company_id}/contacts/${payload.contact_id}`, payload.body, config)
// },

// getContacts (client, { payload, config }) {
//   return client.get(`companies/${payload.company_id}/contacts`, config)
// },
// getContactById (client, { payload, config }) {
//   return client.get(`companies/${payload.company_id}/contacts/${payload.contact_id}`, config)
// },
// updateContact (client, { payload, config }) {
//   return client.put(`companies/${payload.company_id}/contacts/${payload.contact_id}`, payload.body, config)
// },
