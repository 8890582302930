import { useMemo } from 'react';

import { useQueries } from '@tanstack/react-query';

import { baseApi } from 'libs/axios';
import { createUseCompanyContacts } from 'services/companies/keys';
import type {
  ContactResponse,
  ContactsResponse,
} from 'services/contacts/types';
import parseData from 'utils/parseData';

const useFilterContacts = (companies: number[], reports: number[]) => {
  const resultsByCompanies = useQueries({
    queries: (companies ?? []).map((id) => ({
      queryKey: createUseCompanyContacts(id.toString()),
      queryFn: () =>
        baseApi
          .get<ContactsResponse>(`contacts`, {
            params: {
              communications__company_id: id,
              page_size: 1000,
            },
          })
          .then(parseData),
      staleTime: Infinity,
      enabled: companies.length !== 0,
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data?.results),
        isPending: results.map((result) => result.isLoading),
      };
    },
  });

  const resultsByPurpose = useQueries({
    queries: (reports ?? []).map((id) => ({
      queryKey: createUseCompanyContacts(id.toString()),
      queryFn: () =>
        baseApi
          .get<ContactsResponse>(`contacts`, {
            params: {
              communications__communication_purpose_id: id,
              page_size: 1000,
            },
          })
          .then(parseData),
      staleTime: Infinity,
      enabled: reports.length !== 0 && companies.length === 0,
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data?.results),
        isPending: results.map((result) => result.isLoading),
      };
    },
  });

  const contactsByCompanies = useMemo(() => {
    const arr: ContactResponse[] = [];

    resultsByCompanies.data.forEach((i) => {
      i?.forEach((c) => {
        c.communications.forEach((r) => {
          if ((reports ?? []).length) {
            if (reports.includes(Number(r.communication_purpose_id))) {
              if (!arr.find((e) => e.email === c.email && e.name === c.name)) {
                arr.push(c);
              }
            }
          } else {
            arr.push(c);
          }
        });
      });
    });

    return arr.filter(
      (v, i, a) =>
        a.findIndex((v2) => v.name === v2.name && v.email === v2.email) === i,
    );
  }, [reports, resultsByCompanies.data]);

  const contactsByPurpose = useMemo(() => {
    const arr: ContactResponse[] = [];

    resultsByPurpose.data.forEach((i) => {
      i?.forEach((c) => {
        c.communications.forEach((r) => {
          if (reports.includes(Number(r.communication_purpose_id))) {
            if (!arr.find((e) => e.email === c.email && e.name === c.name)) {
              arr.push(c);
            }
          }
        });
      });
    });

    return arr.filter(
      (v, i, a) =>
        a.findIndex((v2) => v.name === v2.name && v.email === v2.email) === i,
    );
  }, [reports, resultsByPurpose.data]);

  const contacts = useMemo(() => {
    return [
      ...contactsByCompanies,
      ...(!(companies ?? []).length ? contactsByPurpose : []),
    ].filter((c) => c.is_active && c.companies.length !== 0);
  }, [companies, contactsByCompanies, contactsByPurpose]);

  const isPending = useMemo(() => {
    return [
      ...resultsByCompanies.isPending,
      ...resultsByPurpose.isPending,
    ].find((i) => i === true);
  }, [resultsByCompanies.isPending, resultsByPurpose.isPending]);

  return {
    contacts,
    isPending,
  };
};

export default useFilterContacts;
