import type {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { PAGE_SIZE } from 'config/constants';
import type { ApiError, ApiResponse } from 'entities/Response';
import { baseApi } from 'libs/axios';
import parseData from 'utils/parseData';

import {
  createUseCommunicationPurpose,
  createUseCommunicationPurposes,
  createUseMail,
  createUseMailingGroup,
  createUseMails,
  createUseTemplateMail,
  createUseTemplateMails,
} from './keys';
import type {
  CommunicationPurposeData,
  CommunicationPurposeResponse,
  CommunicationPurposesParams,
  CommunicationPurposesResponse,
  MailData,
  MailingGroupDeleteData,
  MailingGroupResponse,
  MailResponse,
  MailSendData,
  MailsParams,
  MailsResponse,
  TemplateMailData,
  TemplateMailResponse,
  TemplateMailsParams,
  TemplateMailsResponse,
} from './types';

export const useTemplatesMails = (
  params?: TemplateMailsParams,
  options?: UseQueryOptions<
    TemplateMailsResponse,
    ApiError,
    TemplateMailsResponse
  >,
) => {
  return useQuery({
    queryFn: () => baseApi.get('templates', { params }).then(parseData),
    queryKey: createUseTemplateMails(params),
    ...options,
  });
};

export const useTemplateMail = (
  id: string,
  options?: Partial<
    UseQueryOptions<TemplateMailResponse, ApiError, TemplateMailResponse>
  >,
) => {
  return useQuery({
    queryKey: createUseTemplateMail(id),
    queryFn: () => baseApi.get(`templates/${id}`).then(parseData),
    ...options,
  });
};

export const useUpdateTemplateMail = (
  options?: UseMutationOptions<
    TemplateMailResponse,
    ApiError,
    TemplateMailData & { id: string }
  >,
) => {
  return useMutation<
    TemplateMailResponse,
    ApiError,
    TemplateMailData & { id: string }
  >({
    mutationFn: (data) =>
      baseApi.patch(`templates/${data.id}`, data).then(parseData),
    ...options,
  });
};

export const useCreateTemplateMail = (
  options?: UseMutationOptions<
    TemplateMailResponse,
    ApiError,
    TemplateMailData
  >,
) => {
  return useMutation<TemplateMailResponse, ApiError, TemplateMailData>({
    mutationFn: (data) => baseApi.post(`templates`, data).then(parseData),
    ...options,
  });
};

export const useRemoveTemplateMail = (
  options?: UseMutationOptions<undefined, ApiError, { id: string }>,
) => {
  return useMutation<undefined, ApiError, { id: string }>({
    mutationFn: (data) =>
      baseApi.delete(`templates/${data.id}`).then(parseData),
    ...options,
  });
};

export const useUpdateMail = (
  options?: UseMutationOptions<MailResponse, ApiError, MailData>,
) => {
  return useMutation<MailResponse, ApiError, MailData>({
    mutationFn: (data) =>
      baseApi
        .patch<ApiResponse<MailResponse>>(`mails/${data.id}`, data)
        .then(parseData),
    ...options,
  });
};

export const useCommunicationPurpose = (
  id: string,
  options?: Partial<
    UseQueryOptions<
      CommunicationPurposeResponse,
      ApiError,
      CommunicationPurposeResponse
    >
  >,
) => {
  return useQuery({
    queryFn: () => baseApi.get(`communication-purposes/${id}`).then(parseData),
    queryKey: createUseCommunicationPurpose(id),
    ...options,
  });
};

export const useCreateCommunicationPurpose = (
  options?: UseMutationOptions<
    CommunicationPurposeResponse,
    ApiError,
    Partial<CommunicationPurposeData>
  >,
) => {
  return useMutation({
    mutationFn: (data) =>
      baseApi.post('communication-purposes', data).then(parseData),
    ...options,
  });
};

export const useUpdateCommunicationPurpose = (
  options?: UseMutationOptions<
    CommunicationPurposeResponse,
    ApiError,
    Partial<CommunicationPurposeData & { id: number }>
  >,
) => {
  return useMutation({
    mutationFn: (data) =>
      baseApi.patch(`communication-purposes/${data.id}`, data).then(parseData),
    ...options,
  });
};

export const useCommunicationPurposes = (
  params?: CommunicationPurposesParams,
  options?: Partial<
    UseInfiniteQueryOptions<
      CommunicationPurposesResponse,
      ApiError,
      InfiniteData<CommunicationPurposesResponse>
    >
  >,
) => {
  return useInfiniteQuery({
    queryKey: createUseCommunicationPurposes(params),
    queryFn: ({ pageParam = params?.page }) =>
      baseApi
        .get(`communication-purposes`, {
          params: {
            page: pageParam,
            ...params,
          },
        })
        .then((r) => r.data),
    initialPageParam: 1,
    ...options,
    getNextPageParam: ({ num_pages }) => {
      return Math.ceil(num_pages / PAGE_SIZE) <= (params?.page ?? 0)
        ? (params?.page ?? 0) + 1
        : null;
    },
  });
};

export const useCreateEmail = (
  options?: UseMutationOptions<MailResponse, ApiError, FormData>,
) => {
  return useMutation<MailResponse, ApiError, FormData>({
    mutationFn: (data) =>
      baseApi
        .post(`mails`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(parseData),
    ...options,
  });
};

export const useUpdateEmail = (
  options?: UseMutationOptions<MailResponse, ApiError, Partial<MailResponse>>,
) => {
  return useMutation<MailResponse, ApiError, Partial<MailResponse>>({
    mutationFn: (data) =>
      baseApi.patch(`mails/${data.id}`, data).then(parseData),
    ...options,
  });
};

export const useMail = (
  id: string,
  options?: Partial<UseQueryOptions<MailResponse, ApiError>>,
) => {
  return useQuery<MailResponse, ApiError>({
    queryKey: createUseMail(id),
    queryFn: () => baseApi.get(`mails/${id}`).then(parseData),
    ...options,
  });
};

export const useMails = (
  params?: MailsParams,
  options?: UseQueryOptions<MailsResponse, ApiError>,
) => {
  return useQuery({
    queryKey: createUseMails(params),
    queryFn: () =>
      baseApi
        .get('mails', {
          params: {
            order_by: '-sent_at',
            ...params,
          },
        })
        .then(parseData),
    ...options,
  });
};

export const useSendMail = (
  options?: UseMutationOptions<MailResponse, ApiError, MailSendData>,
) => {
  return useMutation<MailResponse, ApiError, MailSendData>({
    mutationFn: (data) =>
      baseApi
        .post(`mails/${data.id}/send`, {
          sent_by_id: data.user_id,
        })
        .then(parseData),
    ...options,
  });
};

export const useMailingGroup = (
  options?: UseQueryOptions<MailingGroupResponse, ApiError>,
) => {
  return useQuery({
    queryKey: createUseMailingGroup(),
    queryFn: () => baseApi.get('mailing-groups').then(parseData),
    ...options,
  });
};

export const useDeleteMailingGroup = (
  options?: UseMutationOptions<
    MailingGroupResponse,
    ApiError,
    MailingGroupDeleteData
  >,
) => {
  return useMutation({
    mutationFn: (data) =>
      baseApi.delete(`mailing-groups/${data.id}`).then(parseData),
    ...options,
  });
};
