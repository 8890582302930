import { useCallback, useRef, useState } from 'react';

import { EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons';
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  IconButton,
  Text,
} from '@radix-ui/themes';
import { Toast } from 'primereact/toast';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { ROUTES } from 'config/routes';
import { useCreateNewPassword } from 'services/auth';

import IconLoading from 'components/IconLoading';
import InputField from 'components/InputField';

import type { FormData } from './types';

const ConfirmPassword = () => {
  const [passwordVisible1, setPasswordVisible1] = useState<boolean>();
  const [passwordVisible2, setPasswordVisible2] = useState<boolean>();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const password = watch('password');

  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  const navigate = useNavigate();
  const toastBC = useRef<Toast>(null);

  const { mutate, isPending } = useCreateNewPassword();

  const onSubmit = useCallback(
    (form: FormData) => {
      if (token) {
        mutate(
          {
            token,
            password: form.password,
          },
          {
            onSuccess() {
              toastBC.current?.show({
                severity: 'success',
                summary: 'Senha',
                detail: 'Sua senha foi cadastrada com sucesso.',
                life: 5000,
              });

              navigate(ROUTES.PUBLIC.ROOT);
            },
          },
        );
      }
    },
    [mutate, navigate, token],
  );

  return (
    <Flex align="center" justify="center" height="100%">
      <Card size="3" variant="surface" className="signin-form">
        <Heading as="h1" color="gray" mb="6">
          Bem-vindo
        </Heading>

        <Box mb="4">
          <Text color="gray">
            Cadastre uma nova senha para acessar o sistema
          </Text>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            label="Nova senha"
            placeholder="Digite sua senha aqui"
            type={passwordVisible1 ? 'text' : 'password'}
            error={errors.password?.message}
            endAdornment={
              <IconButton
                size="1"
                variant="ghost"
                type="button"
                onClick={() => setPasswordVisible1((prev) => !prev)}
              >
                {passwordVisible1 ? (
                  <EyeOpenIcon height="14" width="14" />
                ) : (
                  <EyeNoneIcon height="14" width="14" />
                )}
              </IconButton>
            }
            {...register('password', {
              required: 'Senha é obrigatório',
            })}
          />

          <InputField
            label="Confirme a nova senha"
            placeholder="Digite sua senha aqui"
            type={passwordVisible2 ? 'text' : 'password'}
            error={errors.password?.message}
            endAdornment={
              <IconButton
                size="1"
                variant="ghost"
                type="button"
                onClick={() => setPasswordVisible2((prev) => !prev)}
              >
                {passwordVisible1 ? (
                  <EyeOpenIcon height="14" width="14" />
                ) : (
                  <EyeNoneIcon height="14" width="14" />
                )}
              </IconButton>
            }
            {...register('confirm_password', {
              required: 'Senha é obrigatório',
              validate: (value) =>
                value === password || 'Senha não confere com a informada',
            })}
          />

          <Flex align="center" justify="between">
            <Box>
              <Button size="3" type="submit" disabled={isPending}>
                {isPending && <IconLoading />} Enviar
              </Button>
            </Box>

            <Box>
              <Link to={ROUTES.PUBLIC.ROOT}>Ir para login</Link>
            </Box>
          </Flex>
        </form>
      </Card>

      <Toast ref={toastBC} />
    </Flex>
  );
};

export default ConfirmPassword;
