import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Button,
  Checkbox,
  Dialog,
  Flex,
  ScrollArea,
  Table,
} from '@radix-ui/themes';

import type { RecipientsDialogProps } from './types';

const RecipientsDialog = ({
  trigger,
  defaultValue,
  value,
  disabled,
  onChange,
}: RecipientsDialogProps) => {
  const [edited, setEdited] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>(
    Object.values(value.map((c) => c.email)),
  );

  const isCheckDisabled = useMemo(() => {
    return defaultValue.length === 1;
  }, [defaultValue.length]);

  const isSupport = useCallback((e: string) => {
    return e === 'suporte@agroenergia.com.br';
  }, []);

  const handleOnChange = useCallback(
    (value: string) => {
      setEdited(true);
      const isChecked = selected.includes(value);

      if (isChecked) {
        setSelected((prevState) => prevState.filter((c) => c !== value));
      } else {
        setSelected((prevState) => [...prevState, value]);
      }
    },
    [selected],
  );

  const onSubmit = useCallback(() => {
    onChange?.(defaultValue.filter((c) => selected.includes(c.email)));
  }, [onChange, defaultValue, selected]);

  const onCancel = useCallback(() => {
    if (edited) {
      setSelected(Object.values(value.map((c) => c.email)));
    }

    onChange?.(value);
  }, [edited, onChange, value]);

  useEffect(() => {
    setSelected(Object.values(value.map((c) => c.email)));
  }, [value]);

  return (
    <Dialog.Root>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>

      <Dialog.Content style={{ maxWidth: 760 }}>
        <Dialog.Title>Selecionar contatos</Dialog.Title>

        <ScrollArea scrollbars="vertical" radius="full" style={{ height: 320 }}>
          <Table.Root variant="surface">
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>E-mail</Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {defaultValue.map((contact, index) => (
                <Table.Row key={index} align="center">
                  <Table.RowHeaderCell>
                    <Checkbox
                      size="2"
                      defaultChecked={selected.includes(contact.email)}
                      onCheckedChange={() => {
                        handleOnChange(contact.email);
                      }}
                      disabled={
                        disabled || isCheckDisabled || isSupport(contact.email)
                      }
                      value={contact.email}
                    />
                  </Table.RowHeaderCell>
                  <Table.Cell>{contact.name}</Table.Cell>
                  <Table.Cell>{contact.email}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        </ScrollArea>

        {!disabled && (
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button onClick={onCancel} variant="soft" color="gray">
                Cancelar
              </Button>
            </Dialog.Close>
            <Dialog.Close>
              <Button onClick={onSubmit} disabled={isCheckDisabled}>
                Selecionar
              </Button>
            </Dialog.Close>
          </Flex>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default RecipientsDialog;
